import { memo, useEffect, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from "react-lazy-load"
import Sliderfordoctor from "./Sliderfordoctor"

function Doctorselect(){
    
    const menu=[
        {
            nmae:"Interventional Radiology",
            img:"../../photo/Radiologya.png",
            to:"/mainservice/radiology",
        },
        {
            nmae:"Orthopaedics",
            img:"../../photo/Orthopaedics.png",
            to:"/mainservice/orthopaedics",
        },
        {
            nmae:"Neurosurgery",
            img:"../../photo/Neurosurgerya.png",
            to:"/mainservice/neurosurgery",
        },
        {
            nmae:"Internal Medicine",
            img:"../../photo/medicine.png",
            to:"/mainservice/internalmedicine",
        },
        {
            nmae:"Cardiology",
            img:"../../photo/hart.png",
            to:"/mainservice/cardiology"
        },
        {
            nmae:"Paediatrics",
            img:"../../photo/Paediatrics.png",
            to:"/mainservice/paediatrics",
        },
        {
            nmae:"Plastic surgery",
            img:"../../photo/Plasticsurgery.png",
            to:"/mainservice/plasticsurgery",
        },
        {
            nmae:"General Surgery",
            img:"../../photo/generals.png",
            to:"/mainservice/generalsurgeon",
        },
        {
            nmae:"OB-GYN",
            img:"../../photo/overy.png",
            to:"/mainservice/obstetrics",
        },
        {
            nmae:"Anesthesiology",
            img:"../../photo/anesthesia.png",
            to:"/mainservice/anesthesiology",
        },
        {
            nmae:"Psychiatry",
            img:"../../photo/Psychiatry.png",
            to:"/mainservice/psychiatry",
        },
        {
            nmae:"Physiotherapy",
            img:"../../photo/Physiotherapy.png",
            to:"/mainservice/physiotherapy",
        },
        {
            nmae:"Urology",
            img:"../../photo/Urology.png",
            to:"/mainservice/urology",
        },
    ]

    const menuone=[
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Bhaskar MV",
            duty:"Senior Interventional Radiologist Endovascular Surgeon",
            exper:"15",
            departs:"Interventional Radiologist",
            quali:"MBBS, MD, (Gold Medal), DNB,DM (NIMHANS),FRCR(London), NMANS(Singapore), EBIR,EDIR",
            depart:["Interventional Radiologist", "Radiologist","Endovascular Surgeon"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Ambuj",
            duty:"Orthopaedic Surgeon",
            exper:"8",
            departs:"Orthopedics",
            quali:"MBBS, MD, Fellow in Diabetic Foot Managament (FDFM)",
            depart:["Orthopedics"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Mithun",
            duty:"Orthopaedic Surgeon",
            exper:"8",
            departs:"Orthopedics",
            quali:"MBBS, MD",
            depart:["Consultant","Orthopedics"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Abilash Srivatsav",
            duty:"Pediatrics Orthopedics",
            exper:"8",
            departs:"Orthopedics",
            quali:"MBBS, MD,Fellow in Pediatric Orthopedics",
            depart:["Orthopedics"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Prajwal",
            duty:"Orthopaedic Surgeon",
            exper:"8",
            departs:"Orthopedics",
            quali:"MBBS, MD",
            depart:["Orthopedics"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Indira Devi",
            duty:"Neuro Surgeon",
            exper:"35",
            departs:"Neurosurgery",
            quali:"MBBS, MS, MCH (NIMHANS)",
            depart:["Neurosurgery"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Bharath R",
            exper:"12",
            departs:"Neurosurgery",
            duty:"Neuro Surgeon",
            quali:"MBBS, MCH(NIMHANS), Fellow Epilepsy surgery (USA)",
            depart:["Neurosurgery"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Wahid",
            duty:"Internal Medicine",
            exper:"5",
            departs:"Internal Medicine",
            quali:"MBBS, MD, (Diabetology)",
            depart:["General Physician","Internal Medicine"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Vikram",
            duty:"Internal Medicine",
            exper:"5",
            departs:"Internal Medicine",
            quali:"MBBS, MD (Fellowship Geriatrics) ",
            depart:["Internal Medicine","Internal Medicine"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Disha Shetty",
            duty:"Cardiologists",
            exper:"4",
            departs:"Cardiology",
            quali:"MBBS, MD, DM(Gold Medal Jayadeva Institute of Cardiology)",
            depart:["Cardiology"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Haridharshan G.J",
            duty:"Pediatrics",
            exper:"6",
            departs:"Pediatric",
            quali:"MBBS, MD ",
            depart:["Pediatric"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Varsha Manohar",
            duty:"Pediatric Neurologists",
            exper:"5",
            departs:"Pediatric",
            quali:"MBBS, MD, Fellow In Pediatrics Neurology",
            depart:["Pediatric"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Somashekar",
            duty:"Aesthetic and Plastic Surgeon",
            exper:"15",
            departs:"Plastic Surgery",
            quali:"MBBS, DNB, MCH",
            depart:["Plastic Surgery"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Deepak M.R",
            duty:"Aesthetic and Plastic Surgeon",
            exper:"15",
            departs:"Plastic Surgery",
            quali:"MBBS, MS, MCH",
            depart:["Plastic Surgery"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Suresh K",
            exper:"5",
            departs:"Plastic Surgery",
            duty:"Aesthetic and Plastic Surgeon",
            quali:"MBBS, MS, MCH, (AIMS)",
            depart:["Plastic Surgery"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Muni Reddy",
            duty:"General Surgeon",
            exper:"13",
            departs:"Gastroenterology",
            quali:"MBBS, MS, MCH",
            depart:["Laproscopic Surgeon","Gastroenterology","General Surgery"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Shaik Ahmed",
            duty:"General Surgeon",
            exper:"8",
            departs:"General Surgery",
            quali:" MBBS, MS",
            depart:["General Surgery"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Sapna G",
            duty:"Obstetricians and Gynecologists",
            exper:"22",
            departs:"Obstetrics & Gynaecology",
            quali:"MBBS, DGO, DNB, FMAS",
            depart:["Obstetrics & Gynaecology"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Pooja R",
            duty:"Obstetricians and Gynecologists",
            exper:"3",
            departs:"Obstetrics & Gynaecology",
            quali:"MBBS, MS",
            depart:["Obstetrics & Gynaecology"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Tulsi Nagesh",
            duty:"Anesthesiologists",
            exper:"35",
            departs:"Anesthesiology & Critical Care",
            quali:"MBBS, DA ",
            depart:["Anesthesiology & Critical Care"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Krishna",
            duty:"Anesthesiologists",
            exper:"12",
            departs:"Anesthesiology & Critical Care",
            quali:"MBBS, MD",
            depart:["Anesthesiology & Critical Care"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Lokesh",
            duty:"Anesthesiologists",
            exper:"15",
            departs:"Anesthesiology & Critical Care",
            quali:"MBBS, MD",
            depart:["Anesthesiology & Critical Care"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Bhavya",
            duty:"Anesthesiologists",
            exper:"10",
            departs:"Anesthesiology & Critical Care",
            quali:"MBBS, MD",
            depart:["Anesthesiology & Critical Care"],
        },
        {
            img:"../../photo/doctor.png",
            names:"Dr. Anupama",
            duty:"Psychiatrist",
            exper:"5",
            departs:"Psychiatry",
            quali:"MBBS, MD(CSDMISER)",
            depart:["Psychiatry"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Arvind",
            duty:"Psychotherapist",
            departs:"Psychotherapy",
            exper:"10",
            quali:"BPT, MPT, Fellow in Neuromuscular Therapy",
            depart:["Psychotherapy"],
        },
        {
            img:"../../photo/doctoro.png",
            names:"Dr. Manjunatha.S",
            duty:"Urologists",
            departs:"Urologists",
            exper:"10",
            quali:"MBBS, MS, MCH",
            depart:["Urology"],
        },
    ]
    const [value, setValue]=useState("Dr Bhaskar M V")
    const [noner, setNoner]=useState()
    const [ondu, setOndu]=useState("block")
    const [erdu, setEradu]=useState("none")
    useEffect(()=>{
         window.addEventListener("load",()=>{
            if(window.innerWidth>992){
                setNoner("flex")
            }else{
                setNoner("none")
            }
         })
         return (()=>{
            window.removeEventListener("load",()=>{})
         })
    })
    const [first, setFirst]=useState(menuone)
    useEffect(()=>{
        const update=menuone.filter((elem)=>{
            // return (elem.names===value)
            const head=(elem.depart)
            return (head.includes(value));
        })
        setFirst(update)
    },[value])
    const changer={
        display:`${noner}`
    }

    return (<>
    <section>
        <div className="container mb-3">
            <div className="row">
                <div className="col-12 textourdoc">
                    <h2>Our Esteemed Doctors</h2>
                </div>
            </div>
            
            <div style={{display:`${ondu}`}} className="col-lg-12 docmaininf">
                <Sliderfordoctor menu={menuone}/>
                </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-12">
                <div onClick={()=>{
                    if(window.innerWidth<992){
                        setNoner("block")}
                }} className="newdirectli doctertittle mb-3 laaa">
                        <h4>DEPARTMENTS</h4>
                    </div>
                    <div style={{display:`${erdu}`,justifyContent:"center"}} className="col-lg-12">
                <div style={{marginBottom:"20px"}} className="row  docmaininf otherbody">
                {
                        first.map((elem)=>{
                            const {img, names, duty, roles, quali,exper,departs}=elem
                            {
                                return (<>
                            <div className="col-xl-4 col-lg-6 col-md-12 col-12 mt-2 selectbot aliyaramaya">
                                <div className="docnameina backcolerd">
                                    <div className="row backcolord">
                                    <div className="doctorone mb-2 col-sm-4 col-12">
                                        <img className="alianer" src={img} alt="" />
                                    </div>
                                    <div className="textdocone col-sm-8 col-12 ">
                                        <h4>{names}</h4>
                                        <div className="row">
                                            <div className="col-12 mb-1"><small>{quali}</small></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-1"><b>{exper}+ Years of Experience</b></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 colorsblue">{departs}</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                                </>)
                            }
                        })
                    }
                </div>
                </div>
                    <div style={changer} className="row docterdis">
                    {
                        menu.map((elem)=>{
                            const {nmae, img, names}=elem
                            {
                                return (<>
                                <div className="col-xl-4 col-lg-4 liselectdoc">
                                <button onClick={()=>{
                                    setValue(nmae)
                                    if(window.innerWidth<992){
                                    setNoner("none")
                                    }
                                    setOndu("none")
                                    setEradu("flex")
                                }} className="newlinkboxs pt-2 pb-2">
                                    <span style={{marginRight:"0px",width:"36px"}} className="newliim footle">
                                        <img className="colorofpng" style={{marginRight:"0px",}} src={img} alt="" />
                                    </span>
                                    <h6>{nmae}</h6>
                                </button>

                                </div>
                                    </>)
                            }
                        })
                    }
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    </>)
}
export default memo(Doctorselect)