import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Fortyser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a14.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Psychiatry</h5>
        <p>Psychiatry is a medical specialty that focuses on the diagnosis, treatment, and prevention of mental illnesses and disorders. Psychiatrists are medical doctors who specialize in psychiatry and are trained to assess and manage the full spectrum of mental health conditions.</p>
        <p>Psychiatrists evaluate patients through interviews, psychological assessments, and observations to diagnose mental disorders. They then develop treatment plans tailored to each individual, which may include various approaches such as medication management, psychotherapy, counseling, and other therapeutic interventions.</p>
        <h6>Here are some key areas of focus within psychiatry:</h6>
        <p>Mood Disorders: Psychiatrists diagnose and treat mood disorders, such as depression, bipolar disorder, and seasonal affective disorder. They may prescribe medications, provide psychotherapy, or recommend other treatment modalities to help stabilize mood and improve quality of life.</p>
        <p>Anxiety Disorders: Psychiatrists address anxiety disorders, including generalized anxiety disorder, panic disorder, social anxiety disorder, and specific phobias. They utilize a combination of medications, cognitive-behavioral therapy (CBT), relaxation techniques, and stress management strategies to alleviate anxiety symptoms and promote well-being.</p>
        <p>Psychotic Disorders: Psychiatrists diagnose and manage psychotic disorders, such as schizophrenia and schizoaffective disorder. They may prescribe antipsychotic medications, provide supportive therapy, and collaborate with other professionals to help individuals manage symptoms and enhance their functioning.</p>
        <p>Substance Use Disorders: Psychiatrists play a crucial role in the diagnosis and treatment of substance use disorders, including addiction to drugs or alcohol. They may employ a combination of medications, counseling, and support groups to help individuals achieve and maintain recovery.</p>
        <p>Eating Disorders: Psychiatrists are involved in the treatment of eating disorders, such as anorexia nervosa, bulimia nervosa, and binge-eating disorder. They work with individuals to address the underlying psychological and emotional factors contributing to disordered eating behaviors and develop comprehensive treatment plans involving therapy, nutritional counseling, and medical management.</p>
        <p>Psychiatrists often work as part of a multidisciplinary team, collaborating with psychologists, social workers, nurses, and other healthcare professionals to provide holistic care. They may also provide consultation and support to primary care physicians and other specialists regarding the psychiatric aspects of patient care.</p>
        <p>If you or someone you know is experiencing mental health concerns, it is recommended to consult with a qualified psychiatrist for a comprehensive evaluation and individualized treatment plan. Mental health is an important aspect of overall well-being, and seeking timely professional help can make a significant difference in managing and improving mental health conditions.</p>
    </div>
    </>)
}
export default memo(Fortyser)