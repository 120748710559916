import { memo, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from "react-lazy-load"

function Mediacenter(){
    const menu=[
        {
            img:"../photo/b1.jpg",
            name:"4 Common Diabetes - Related Fears People Have and How to Overcome Them",
            content:"Diabetes commonly known as life style disorder often leaves its toll upon the mental health of people. People detected with diabetes have to monitor their blood..",
        },
        {
            img:"../photo/b2.jpg",
            name:"Psychological Treatment: Do You Really Need It? This Will Help You Decide!",
            content:"What exactly is a psychiatric disorder? Psychological illnesses, often known as mental disorders, are mental imbalances that lead to persistent specific behavio..",
        },
        {
            img:"../photo/b3.jpg",
            name:"Everything about Cardiac Arrest",
            content:"Cardiac arrest is a medical condition occurs due to sudden cessation of heart functioning. The function of heart is to send oxygenated blood to the body. Cardia..",
        },
        {
            img:"../photo/b4.jpg",
            name:"Benefits of Exercise for a Healthy Living",
            content:"Modern life being fast and full of stress leaves its toll upon human beings. In most of the metropolitan cities people are suffering from diseases like diabetes..",
        },
        {
            img:"../photo/b5.jpg",
            name:"Covid Vaccines and their Protective Power",
            content:"Ever since Covid-19 pandemic had broken out in the world, the developed and developing countries like India engage themselves in preparing its vaccine to protec..",
        },
    ]
    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    useEffect(()=>{
        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },2000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(100)
                }else if(window.innerWidth>992){
                    setNumber(100)
                }else if(window.innerWidth>768){
                    setNumber(100)
                }else{
                    setNumber(100)
                }
                first();
                if(index>4){
                    second()
                }
            }
            iamgod()
        },3000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }
    return (<>
    <section className="secmedia">
        <div className="container">
            <div className="row">
                <div className="mediaheader mb-4">
                    <h6>MEDIA CENTER</h6>
                    <h1>latest Updates & Articles</h1>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-lg-6">
                <div className="rowhomehi">
                <div style={changer} className="rowhome">
                    {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div className="col-12 backcontroll">
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="textinmedia">
                                            <h4>{name}</h4>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
                    {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div className="col-12 backcontroll">
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="textinmedia">
                                            <h4>{name}</h4>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
                </div>
                </div>
                </div> */}
            <div className="col-12 relative">
                 <div className="absul">
                    <h5 className="latestpost"></h5>
                    <h5 className="latestposth4">Latest Posts</h5>
                </div>
                <div className="seclater p-2 pt-4">
                <div className="mt-4 mb-1">
                    <h4>Introducing Karnataka’s First Cryo Laser and Cryo Sclerotherapy (CLaCS) at Adhventha Hospital, Bangalore</h4>
                    <p>Bangalore has always been at the forefront of medical advancements, and once again...</p>
                    <Link to="/subblog" className="noreinfo">MORE INFO</Link>
                    </div>
                    <div className="mt-4 mb-1">
                    <h4>Laser Treatment for Varicose Veins and Thyroid Ablation</h4>
                    <p>As medical technology advances, laser treatments are transforming how we manage various health
                    conditions...</p>
                    <Link to="/subblog" className="noreinfo">MORE INFO</Link>
                    </div>
                    <div className="mt-4 mb-1">
                    <h4>The Ultimate Guide to Varicose Vein Laser Treatment</h4>
                    <p>Varicose veins are a common concern that affects millions, causing both aesthetic discomfort and, in many
                    cases, significant pain...</p>
                    <Link to="/subblog" className="noreinfo">MORE INFO</Link>
                    </div>
                    <div className="mt-4 mb-1">
                    <h4>Revolutionary Laser Therapies</h4>
                    <p>Laser technology is changing the face of modern medicine, providing minimally invasive, precise
                    treatments for conditions...</p>
                    <Link to="/subblog" className="noreinfo">MORE INFO</Link>
                    </div>
                    {/* <div className="mt-4 mb-1">
                    <h4>Endovenous Laser Treatment (EVLT) for Varicose Veins</h4>
                    <p>TVaricose veins affect millions of people worldwide, leading to discomfort, swelling, and visible, bulging
                    veins that often affect confidence...</p>
                    <Link to="/subblog" className="noreinfo">MORE INFO</Link>
                    </div> */}
                </div>
            </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Mediacenter)