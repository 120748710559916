import { memo, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Controller(props) {
  function fromchanger() {
    props.setFormcontrol("block");
  }
  return (
    <>
      <div className="conboxmain">
        <a href="https://wa.me/9342595404?text=Hello, How can I help you??">
          <div className="controlthree">
            <LazyLoadImage src="../photo/whatsapp.png"></LazyLoadImage>
          </div>
        </a>
        <div onClick={fromchanger} className="controltwo crosaler">
          <LazyLoadImage src="../../photo/message.png"></LazyLoadImage>
        </div>
        <a href="tel:9342595404">
          <div className="controlthreea">
            <LazyLoadImage src="../../photo/telephone.png"></LazyLoadImage>
          </div>
        </a>
      </div>
    </>
  );
}
export default memo(Controller);
