
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import NewLinkbox from "./NewLinkbox"
import { memo } from "react"
function Headertwo(props){
    return (<>
    <header>
        <NewLinkbox first={props.first} setFirst={props.setFirst} second={props.second} setSecond={props.setSecond} setThree={props.setThree}/>
    </header>
    </>)
}
export default memo(Headertwo)