import CoreValue from "../Corevallue";
import Hospitalinfo from "../Hospitalinfo";
import Mainservice from "../MainService";
import Slider from "../Slider";
import Newfooter from "../NewSections/Newfooter";
import Homeblog from "../NewSections/Homeblog";
import Newcontactin from "../NewSections/Newcontactin";
import Newservices from "../NewSections/Newservices";
import Doctorselect from "../NewSections/Doctorselect";
import Contactinbox from "../NewSections/Contactinbox";
import Reviewbox from "../NewSections/Reviewbox";
import Headernewtop from "../NewSections/Headernewtop";
import { memo } from "react";
import ContactUsinfo from "../CintactUsinfo";
import Controller from "../ContactButton/Controller";
import Contactbutton from "../ContactButton/Contactbutton";
import Mobcontroller from "../ContactButton/Mobcontroller";
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo";
import Newcontactsescter from "../NewSections/Newcontactsescter";
import Numberinfo from "../Newservicesbox/Numberinfo";
import Contactinforlink from "../Newservicesbox/Contactinforlink";
import Vissionandmission from "../About/VissionandMission";

function Home(){
    return (<>
    <div>
        
    <Contactbutton/>
        <Newcontactsescter/>
        <Mobcontroller/>
    <Sidelogo/>
    <Headernewtop/>
    <Slider/>
    {/* <Mainservice/> */}
    <Hospitalinfo/>
    <div id="helloservice">
        
    <Newservices/>
    </div>
    <Doctorselect/>
    <Numberinfo/>
    <Contactinbox/>
    <Vissionandmission/>
    {/* <CoreValue/> */}
    {/* <ContactUsinfo/> */}
    <Reviewbox/>
    <Homeblog/>
    <Contactinforlink/>
    <Newfooter/>
    </div>
    </>)
}
export default memo(Home);