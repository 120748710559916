import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Levenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a11.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Paediatrics</h5>
        <p>Pediatrics, also spelled as "paediatrics," is a medical specialty that focuses on the healthcare of infants, children, and adolescents. Pediatricians are medical doctors who specialize in pediatrics and provide medical care for children from birth until adolescence.</p>
        <h6>Pediatricians are trained to address a wide range of healthcare needs specific to children, including:</h6>
        <h6>Well-child care:</h6>
        <p>Pediatricians provide routine check-ups, vaccinations, and screenings to monitor a child's growth and development, as well as provide guidance on nutrition, safety, and healthy lifestyle habits. These regular visits also offer an opportunity to address any concerns or questions parents may have about their child's health.</p>
        <h6>Acute illnesses and injuries:</h6>
        <p>Pediatricians diagnose and treat common childhood illnesses, such as respiratory infections, ear infections, gastrointestinal disorders, and skin conditions. They also manage minor injuries and provide appropriate care and advice for a speedy recovery.</p>
        <h6>Chronic medical conditions:</h6>
        <p>Pediatricians manage and coordinate the care of children with chronic medical conditions, such as asthma, diabetes, allergies, epilepsy, and autoimmune disorders. They work closely with other specialists to provide comprehensive care and help children and their families manage these conditions effectively.</p>
        <h6>Developmental and behavioral issues:</h6>
        <p>Pediatricians monitor and evaluate a child's development, including milestones in physical, cognitive, and social-emotional domains. They screen for developmental delays, learning disabilities, and behavioral issues and provide appropriate interventions or referrals to specialists when necessary.</p>
        <p>Pediatricians develop a rapport with children and their families, establishing a relationship built on trust and open communication. They often provide guidance and support to parents and caregivers, addressing concerns related to growth, behavior, parenting techniques, and emotional well-being.</p>
        <p>Pediatricians work closely with other healthcare professionals, including pediatric nurses, pediatric specialists (such as pediatric cardiologists, pediatric gastroenterologists, pediatric neurologists, etc.), pediatric surgeons, and allied healthcare professionals, to ensure comprehensive care for children.</p>
        <p>If you have concerns about your child's health or development, it is advisable to consult with a qualified pediatrician who can provide appropriate medical evaluation, diagnosis, treatment, and preventive care tailored to your child's specific needs. Pediatricians play a vital role in promoting the health and well-being of children and supporting their families in the journey of raising healthy and happy individuals.</p>
    </div>
    </>)
}
export default memo(Levenser)