import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Twlvser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a12.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Pathology</h5>
        <p>Pathology is a medical specialty that focuses on the study of disease, including the causes, mechanisms, and effects of diseases. Pathologists are medical doctors who specialize in pathology and play a crucial role in the diagnosis and understanding of various diseases.</p>
        <p>Pathologists examine tissues, cells, and body fluids to identify abnormalities and provide important diagnostic information to guide patient care. They work in collaboration with other healthcare professionals, including primary care physicians, surgeons, and oncologists, to help determine the most appropriate treatment options for patients. Here are a few areas of focus within pathology:</p>
        <h6>Anatomic Pathology:</h6>
        <p>Anatomic pathologists examine tissues obtained from biopsies, surgical specimens, or autopsies under a microscope. They identify abnormal cellular or tissue structures and provide diagnoses for conditions such as cancers, infectious diseases, autoimmune disorders, and congenital anomalies. Anatomic pathologists may also perform intraoperative consultations to guide surgeons during procedures.</p>
        <h6>Clinical Pathology:</h6>
        <p>Clinical pathologists analyze body fluids, such as blood, urine, and cerebrospinal fluid, to aid in the diagnosis and monitoring of diseases. They use laboratory techniques, including blood tests, genetic testing, and microbiology studies, to detect abnormalities in body chemistry, hormones, blood cells, and microorganisms. Clinical pathologists play a crucial role in the interpretation of laboratory results and the selection of appropriate tests for diagnosis and monitoring.</p>
        <h6>Molecular Pathology:</h6>
        <p>Molecular pathologists study the genetic and molecular changes that occur in diseases. They analyze DNA, RNA, and proteins to identify specific genetic mutations, gene expression patterns, or alterations in cellular pathways that contribute to disease development and progression. Molecular pathology is particularly important in personalized medicine, where targeted therapies are tailored to specific genetic mutations or biomarkers.</p>
        <p>Forensic pathologists investigate the cause and manner of death in cases of sudden or unexpected deaths. They perform autopsies, collect evidence, and provide expert testimony in legal proceedings. Forensic pathologists play a crucial role in determining the cause of death in cases of accidents, homicides, suicides, and natural deaths with unclear circumstances.</p>
        <p>Pathologists also contribute to medical research, studying the underlying mechanisms of diseases, evaluating the effectiveness of treatments, and discovering new diagnostic techniques. They play a vital role in quality assurance and improvement in healthcare settings, ensuring accurate and reliable laboratory testing and diagnoses.

It's important to note that pathologists do not typically have direct patient contact but work behind the scenes to provide critical diagnostic information and contribute to patient care. If you have concerns about a specific medical condition or require diagnostic tests, your primary care physician or specialist may consult with a pathologist to help guide your diagnosis and treatment plan.</p>
    </div>
    </>)
}
export default memo(Twlvser)