import { memo, useEffect, useState } from "react"
import LazyLoad from "react-lazy-load"

function Blogbanner(){
    const menu=[
        {
            img:"../photo/ss9.jpg",
            name:"Laser Treatment for Varicose Veins and Thyroid Ablation",
            content:"As medical technology advances, laser treatments are transforming how we manage various health conditions...",
        },
        {
            img:"../photo/ss7.jpg",
            name:"The Ultimate Guide to Varicose Vein Laser Treatment",
            content:"Varicose veins are a common concern that affects millions, causing both aesthetic discomfort and, in many cases, significant pain...",
        },
        {
            img:"../photo/ss6.jpg",
            name:"Revolutionary Laser Therapies",
            content:"Laser technology is changing the face of modern medicine, providing minimally invasive, precise treatments for conditions...",
        },
        {
            img:"../photo/ss5.jpg",
            name:"Endovenous Laser Treatment (EVLT) for Varicose Veins",
            content:"TVaricose veins affect millions of people worldwide, leading to discomfort, swelling, and visible, bulging veins that often affect confidence...",
        },
    ]
    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    useEffect(()=>{
        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },2000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(100)
                }else if(window.innerWidth>992){
                    setNumber(100)
                }else if(window.innerWidth>768){
                    setNumber(100)
                }else{
                    setNumber(100)
                }
                first();
                if(index>4){
                    second()
                }
            }
            iamgod()
        },3000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }
    return (<>
        <div className="topperseers">
        <div className="container topperseer">
            <div className="row py-5">
                <div className="col-xl-4 col-md-6 col-12 centerimg">
                    <div className="textinthe">
                        
                    <h4>"Discover unparalleled compassion and medical expertise in diagnostics at Adhventha Hospital, ensuring an exceptional care experience."</h4>
                    <p>Adhventha Hospital holds a strong dedication to offering patients unparalleled medical diagnostic services, boasting a team of immensely skilled and specialized physicians, certified technologists, and empathetic support personnel. Our focus is on delivering top-tier imaging, laboratory, and extensive health check services, always conducted with the utmost courtesy and respect.</p>
                    </div>
                </div>
                <div className="col-xl-4 hiddeb">
                        <LazyLoad className="w-100 centerimg h-100">
                            <img className="w-50" src="../../photo/Framelogo.png" alt="" />
                        </LazyLoad>
                    </div>
                    <div className="col-xl-4 col-md-6 col-12">
                    <div className="rowhomehi">
                <div style={changer} className="rowhome">
                    {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div className="col-12 backcontroll">
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="textinmedia">
                                            <h6>{name}</h6>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
                    {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div className="col-12 backcontroll">
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="textinmedia">
                                            <h6>{name}</h6>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
                </div>
                </div>
                    </div>
            </div>
        </div>
        </div>
    </>)
}
export default memo(Blogbanner)