import { memo, useState } from "react"
import Newfixheader from "./Newfixheader"
import Headertwo from "./Headertwo"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import Newmobfirst from "./Newmobfirst"
import Newmobsecond from "./Newmobsecond"

function Headernewtop(){
    const [first, setFirst]=useState("none");
    const [second, setSecond]=useState("none");
    const [three, setThree]=useState("block")
    const changer={
        display:`${three}`
    }
    return (<>
        <Newmobfirst first={first} setFirst={setFirst} second={second} setSecond={setSecond} setThree={setThree}/>
        <Newmobsecond first={first} setFirst={setFirst} second={second} setSecond={setSecond} setThree={setThree}/>
    <div>
        <Newfixheader first={first} setFirst={setFirst} second={second} setSecond={setSecond} setThree={setThree}/>
    </div>
    <div>
    <Headertwo first={first} setFirst={setFirst} second={second} setSecond={setSecond} setThree={setThree}/>
    </div>
    </>)
}
export default memo(Headernewtop)