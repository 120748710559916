import { memo, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from "react-lazy-load"

function Review(){
    const menu=[
        {
            img:"../photo/go.jpg",
            star:"../photo/star.png",
            name:"Madanica S Mohan",
            content:"The orthopedic specialists at Adhventha Hospital are truly exceptional. Their expertise and knowledge in the field of orthopedics are commendable. The doctors took the time to thoroughly assess my condition, explain the treatment options, and address all my concerns. They demonstrated a high level of professionalism, expertise, and genuine care throughout my entire treatment journey.",
        },
        {
            img:"../photo/bo.jpg",
            star:"../photo/star.png",
            name:"Santosh Patil",
            content:"I recently had the privilege of being under the care of Dr. Indira Devi, a renowned neurosurgeon, and I am truly grateful for the exceptional experience I had. Dr. Indira Devi is an exemplary professional who has not only demonstrated exceptional expertise in the field of neurosurgery but also showcased a deep commitment to patient care.",
        },
        {
            img:"../photo/gt.jpg",
            star:"../photo/star.png",
            name:"Devaraj Desay",
            content:"The hospital's facilities are top-notch. From the reception area to the examination rooms, everything was clean, organized, and comfortable. Adhventha Hospital has made significant investments in advanced technology and equipment. I was impressed by the state-of-the-art diagnostic tools and surgical facilities available, which undoubtedly contributed to the accuracy of my diagnosis and the success of my treatment.",
        },
        {
            img:"../photo/bt.jpg",
            star:"../photo/star.png",
            name:"Nandana Gosavi",
            content:"One of the remarkable qualities of dr Bhaskar M V is their exceptional communication skills. They effectively conveyed complex medical information in a clear and concise manner, eliminating any confusion or ambiguity. dr Bhaskar M V patiently answered all my questions, ensuring that I had a comprehensive understanding of my radiological evaluation.",
        },
        {
            img:"../photo/gtt.jpg",
            star:"../photo/star.png",
            name:"Ramsing Patidar",
            content:"The medical staff at Adhventha Hospital are highly skilled and competent professionals. From the doctors and nurses to the support staff, everyone demonstrated a deep understanding of their respective roles and responsibilities. The medical team's expertise and knowledge were evident throughout my treatment, providing me with a sense of confidence and trust in the care I was receiving.",
        },
    ]


    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    useEffect(()=>{
        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },2000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(50)
                }else if(window.innerWidth>992){
                    setNumber(50)
                }else if(window.innerWidth>768){
                    setNumber(100)
                }else{
                    setNumber(100)
                }
                first();
                if(index>4){
                    second()
                }
            }
            iamgod()
        },3000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }

    return (<>
    <section className="secreview">
        <div className="container">
            <div className="row">
                <div className="col-12"></div>
                <div className="col-lg-12 reviewone">
                    <h2 style={{fontSize:"42px"}}>Testimonitals</h2>
                    <h2 style={{fontSize:"28.5px"}}>Hear Our Clients Feedback</h2>
                </div>
            </div>
            <div className="rowhomehi">
                <div style={changer} className="rowhome">

                    {
                        menu.map((elem)=>{
                            const {img, star, name, content}=elem
                            {
                                return (
                                    <div className="col-lg-6 col-12 reviewsix">
                        <div className="reviewfive">
                            <div className="row">
                                <div className=" col-xl-4 col-lg-12 col-md-4 col-12 reviewtwo">
                                    <img src={img} alt="" />
                                </div>
                                <div className="col-xl-8 col-lg-12 col-md-8 col-12">
                                <div className="threereview">
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                </div>
                                <div className="fourreview">
                                <h6>{name}</h6>
                                <p>{content}</p>
                                </div>
                                </div>
                            </div>
                       </div>   
                    </div>
                                )
                            }
                        })
                    }
                    
                    {
                        menu.map((elem)=>{
                            const {img, star, name, content}=elem
                            {
                                return (
                                    <div className="col-lg-6 col-12 reviewsix">
                        <div className="reviewfive">
                            <div className="row">
                                <LazyLoad className=" col-xl-4 col-lg-12 col-md-4 col-12 reviewtwo">
                                    <img src={img} alt="" />
                                </LazyLoad>
                                <div className="col-xl-8 col-lg-12 col-md-8 col-12">
                                <div className="threereview">
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                <img src={star} alt="" />
                                </div>
                                <div className="fourreview">
                                <h6>{name}</h6>
                                <p>{content}</p>
                                </div>
                                </div>
                            </div>
                       </div>   
                    </div>
                                )
                            }
                        })
                    }

                    
                    <div className="col-lg-6 col-12 reviewsix">
                        <div className="reviewfive">
                            <div className="row">
                                <LazyLoad className=" col-xl-4 col-lg-12 col-md-4 col-12 reviewtwo">
                                    <img src="../photo/hart.png" alt="" />
                                </LazyLoad>
                                <div className="col-xl-8 col-lg-12 col-md-8 col-12">
                                <div className="threereview">
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                </div>
                                <div className="fourreview">
                                <h6>Madanica S Mohan</h6>
                                <p>Very approachable staff and nurse. Treated very well with care. Nurse Kavya was very kind and gentle. Sister Nadini and Pramila took good care. Supervisor Murugesh,Bharath and  Housekeeping staff Lakshmi took special interest in room cleaning ,maintaining washroom hygiene.overall process was very smooth.</p>
                                </div>
                                </div>
                            </div>
                       </div>   
                    </div>

                                       
                    <div className="col-lg-6 col-12 reviewsix">
                        <div className="reviewfive">
                            <div className="row">
                                <LazyLoad className=" col-xl-4 col-lg-12 col-md-4 col-12 reviewtwo">
                                    <img src="../photo/hart.png" alt="" />
                                </LazyLoad>
                                <div className="col-xl-8 col-lg-12 col-md-8 col-12">
                                <div className="threereview">
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                <img src="../photo/star.png" alt="" />
                                </div>
                                <div className="fourreview">
                                <h6>Madanica S Mohan</h6>
                                <p>Very approachable staff and nurse. Treated very well with care. Nurse Kavya was very kind and gentle. Sister Nadini and Pramila took good care. Supervisor Murugesh,Bharath and  Housekeeping staff Lakshmi took special interest in room cleaning ,maintaining washroom hygiene.overall process was very smooth.</p>
                                </div>
                                </div>
                            </div>
                       </div>   
                    </div>
               

    </div>
            </div>
        </div>
    </section>
    </>)
}
export default Review;