import { memo } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"


function MobileControlform(props){
    const stayler={
        display:`${props.formcontrol}`
    }
    function changer(){
        props.setFormcontrol("none")
    }
    window.addEventListener("resize",()=>{
        if(window.innerWidth<768){
            props.setFormcontrol("none")
        }
    })
    return (<>
            <div style={stayler} className="mobconform">
        <div>
        <form className="innerform innermobcon">
            <div className="row contitle">
                <h5>Contact Us</h5>
            </div>
            <div>
                <label><h6>Your Name</h6></label><br></br>
                <input className="inconlas"></input><br></br>
                <label><h6>Your Email</h6></label><br></br>
                <input className="inconlas"></input><br></br>
                <label><h6>Phone Number</h6></label><br></br>
                <input className="inconlas"></input><br></br>
                <label><h6>Message</h6></label><br></br>
                <textarea className="meassageconfor" name="" id="" cols="30" rows="10"></textarea>
            </div>
            <div className="subconfor">
                <button className="subformbox">Submit</button>
            </div>
        </form>
        <div onClick={changer} className="errorbox">
            <LazyLoadImage src="../../photo/error.png"></LazyLoadImage>
        </div>
        </div>
        </div>
    </>)
}
export default memo(MobileControlform);