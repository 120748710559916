import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function EightSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b8.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Childhood though is considered to be the best part of human life the children are not free from illness and diseases. In fact, they are most vulnerable to fall sick in the initial years when their immunity is not developed fully. The parents cannot prevent their kids from being sick. As soon as they step out of the comfort zone of their houses and come in contact with other children and adults they fall sick. The diseases transported from the other children they meet in schools, Montessori houses and day care centres. The children are most susceptible to catch cold and cough and common flu from others including adults. The other common childhood diseases are:</p>
        <p>1) Chickenpox (varicella)</p>
        <p>2) Whooping cough (pertussis)</p>
        <p>3) Measles, Mumps, Rubella</p>
        <p>4) Rotavirus</p>
        <p>5) Tetanus</p>
        <p>5) Influenza</p>
        <p>Some common childhood diseases are either caused by airborne virus or spread through direct contact with the infected person. In schools or day care centres when a child comes in close proximity with another child suffering from cold or cough he/she may get infected. Else when a child sneezes or coughs, the germs may spread through air. Again when a child touches the objects like books, copies and toys touched by other children, the germs may get transmitted.

The primary way to keep these infectious diseases at bay is to teach the children about hygiene. They should be trained to wash their hands, not to put hands into mouth every now and then.</p>
<h5>Treatment for Common Childhood Diseases</h5>
<p>There are specific treatments for the common childhood diseases. One of the most important treatment procedures is vaccination. To protect the children from the common childhood diseases, the vaccination starts from the birth. They are given BCG doses, polio vaccine, MMR Vaccine etc. The paediatricians prepare specific vaccination chart for the children following the Government specified immunisation programme. It is the duty of the parents to take their kids to the doctors to get them vaccinated on time.</p>
    </div>
    </>)
}
export default memo(EightSub)