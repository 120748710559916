import { memo } from "react"

function Sidelogo(){
    return (<>
    <div>
    <div className="fixicon">
                        <div className="iconboxsli">
                        <a className="homecall textimg paddimg firsticon" href="https://www.facebook.com/adhventhahospital">  
                            <img style={{width:"40px",paddingBottom:"10px"}} src="../../photo/facebook.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="https://www.instagram.com/adhventhahospital/">  
                            <img style={{width:"40px",paddingBottom:"10px"}} src="../../photo/instagram.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon" href="https://www.google.com/maps/place/Adhventha+Hospital/@12.9175633,77.5754202,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15858e534d51:0x1f5960042a254008!8m2!3d12.9175633!4d77.5776089!16s%2Fg%2F11khnn6jsd?entry=tts&shorturl=1">  
                            <img style={{width:"40px",paddingBottom:"10px"}} src="../../photo/google-maps.png" alt="" />
                        </a>
                        <a className="homecall textimg paddimg firsticon " href="">  
                            <img style={{width:"40px",paddingBottom:"10px"}} src="../../photo/twitter.png" alt="" />
                        </a>
                        </div>
                    </div>
    </div>
    </>)
}
export default memo(Sidelogo)