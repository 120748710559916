import { useState } from "react";
import Footer from "../Footer";
import HeaderTop from "../Header-top";
import Headmenu from "../Headmenu";
import Mobilemenu from "../Mobile-menu";
import Contactbanner from "./Contactbanner";
import ContactUsinfo from "../CintactUsinfo";
import Whywecontact from "./Whycontact";
import Newfooter from "../NewSections/Newfooter";
import Headertwo from "../NewSections/Headertwo";
import CoreValue from "../Corevallue";
import Mainservice from "../MainService";
import Headernewtop from "../NewSections/Headernewtop";
import Mobcontroller from "../ContactButton/Mobcontroller";
import Contactbutton from "../ContactButton/Contactbutton";
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo";
import Newservicebanner from "../Newservicesbox/Newservicebanner";
import Vm from "../About/vm";
import Vissionandmission from "../About/VissionandMission";
import { useEffect } from "react";
import Functionmain from "../Functionmain";
import bannercontent from "../Newservicesbox/Bannercontent";
function ContactUs(props){
    useEffect(()=>{
        Functionmain()
    },[])
    return (<>
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
    <Headernewtop/>
    {
        bannercontent.map((elem)=>{
            const {page, one, two}=elem
            if("contact"==page)
            {
                return (<>
                    <Newservicebanner one={one} two={two}/>
                    </>)
            }
        })
    }
        <Mainservice/>
        <div className="mt-3 mb-3"> 
        {/* <Vissionandmission/> */}
        </div>
        <ContactUsinfo/>
        <Newfooter/>
    </>)
}
export default ContactUs;