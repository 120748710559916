import { memo } from "react"
import LazyLoad from "react-lazy-load"

function TenSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b11.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Certain surgical procedures do not require hospitalisation and can be done by surgeons at the outpatient facilities of the hospital. The outpatient surgical procedures can also be done in the inpatient facilities and in the freestanding self-contained unit known as an ambulatory surgery centre. Such outpatient surgical procedures done at ambulatory surgery centre hold the accreditation of NABH (National Accreditation Board for Hospitals and Healthcare Providers). These surgical procedures are less complicated compared to the intensive surgeries and the patients are released few hours after the surgery.</p>
        <p>Of late ‘Outpatient Surgery’ done at accredited surgical facility has become very popular in many countries including India. It is considered safe and secured and minimizes the cost of surgery since the patients do not require hospitalization. Some common outpatient surgeries are ‘cataract surgery’, ‘dental surgery’, ‘rotator cuff repair’, ‘small joint repair’, ‘meniscus repair’, ‘nerve treatment’, ‘lumpectomy’, ‘skin treatment’, ‘tonsillectomy’, and ‘removal of nasal polyp’ etc.</p>
        <p>Hospitals with accredited surgical facility are abide by the strict standards of safety, cleanliness, national standards for equipment, operating room safety, surgeon and staff credentials to ensure that patients receive the best care and treatment following all the safety measures.</p>
        <h5>Advantages of Surgical Procedures Done at Accredited Surgical Facilities</h5>
        <p>Surgical procedures done at an outpatient department of an accredited surgical facility unit has several benefits.There is an extremely low rate of serious complications (less than half of 1 percent)Such surgeries have an extremely low mortality rate (less than one in 57,000)The surgeries are done at lower costsThe patient can return back home on the same day.

In case, the patients need overnight recuperation, the accredited surgical facility will arrange for the special hospitalization and patient care.</p>
<h5>Certain Things to Check about Accredited Surgical Facilities</h5>
<p>All accredited surgical facilities must maintain certain safety standard and protocols.These centres must be run and attended by licensed doctors, nurses and healthcare staffs.The surgical tools and instruments must be checked regularly for their proper functioning. These instruments should be thoroughly sterilised.Operating room staff must be capable of responding swiftly and effectively in case an emergency.Besides, these centres must carry the accreditation of the authorised accreditation centre.Accredited Surgical Facilities must adhere to all local, state and national regulations including sanitation, fire safety and building codes.These centres should abide by the laws and Occupational Safety and Health Administration (OSHA) regulations including blood-borne pathogen and hazardous waste standardsThere must be enough arrangements for advanced monitoring for patients’ safety during surgery and immediate recovery.</p>
<p>The patients and their relatives must verify the accreditation of the outpatient surgical facility before consenting for an outpatient surgical procedure.</p>
    </div>
    </>)
}
export default memo(TenSub)