import { memo } from "react"
import LazyLoad from "react-lazy-load"

function GeneralMedicine(){
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>General Medicine</h2>
                    <p>General medicine, also known as internal medicine or primary care, is a medical specialty that focuses on the comprehensive care of adults for a wide range of health issues. General medicine practitioners, called general physicians or internists, are trained to provide primary healthcare services, including preventive care, diagnosis, treatment, and management of various medical conditions.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>General medicine encompasses a broad spectrum of health concerns and conditions. Some of the common areas of focus within general medicine include.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/cc1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Routine check-ups</h6>
                            <p>General physicians perform regular check-ups to assess overall health, monitor vital signs, and screen for potential health risks.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/cc2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Management of chronic conditions</h6>
                            <p>General physicians are trained to diagnose and manage chronic diseases such as diabetes, hypertension, asthma, COPD, and heart disease.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/cc3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Infectious diseases</h6>
                            <p>General physicians diagnose and treat various infectious diseases, such as respiratory infections, urinary tract infections, gastrointestinal infections, and skin infections.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/cc4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Gastrointestinal disorders</h6>
                            <p>General physicians address conditions related to the digestive system, including acid reflux, irritable bowel syndrome (IBS), gastritis, and inflammatory bowel disease.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/cc5.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Respiratory conditions</h6>
                            <p>General physicians manage respiratory conditions such as asthma, chronic bronchitis, pneumonia, and chronic obstructive pulmonary disease (COPD). </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className="container mt-5 mb-5">
        <div className="centers">
                <h5>Our Expertize Doctors</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctoro.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Shaik Ahmed</h5>
                                <h6>General</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(GeneralMedicine)