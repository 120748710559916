import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Thretyser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a13.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Plastic surgery</h5>
        <p>Plastic surgery is a surgical specialty that focuses on the reconstruction, restoration, or alteration of the human body. It involves both reconstructive procedures to restore function and form, as well as cosmetic procedures to enhance or improve the appearance of various body parts. Plastic surgeons are medical doctors who specialize in plastic surgery and undergo extensive training to perform a wide range of surgical procedures.</p>
        <h6>Here are some common areas of focus within plastic surgery:</h6>
        <h6>Reconstructive Surgery:</h6>
        <p>Reconstructive surgery aims to restore the function and appearance of body parts affected by congenital conditions, trauma, infections, tumors, or other medical conditions. Examples of reconstructive procedures include breast reconstruction after mastectomy, cleft lip and palate repair, hand surgery, and scar revision.</p>
        <h6>Cosmetic Surgery:</h6>
        <p>Cosmetic surgery, also known as aesthetic surgery, involves procedures that are primarily performed to enhance or improve a person's appearance. These procedures are typically elective and address concerns related to the face, breasts, body contouring, and skin. Some examples of cosmetic procedures include breast augmentation, facelift, rhinoplasty (nose reshaping), liposuction, and abdominoplasty (tummy tuck).</p>
        <h6>Burn Reconstruction:</h6>
        <p> Plastic surgeons play a critical role in the management of burn injuries. They perform reconstructive surgeries to restore both form and function after severe burns, including skin grafts, tissue expansion, and scar revision procedures.</p>
        <h6>Hand Surgery:</h6>
        <p>Hand surgery involves the treatment of conditions affecting the hand and upper extremities, such as fractures, tendon injuries, nerve injuries, carpal tunnel syndrome, and congenital deformities. Plastic surgeons with specialized training in hand surgery are skilled in intricate procedures to restore hand function and improve quality of life.</p>
        <p>Plastic surgeons use a variety of techniques and technologies to achieve desired outcomes, including tissue grafting, implantation of synthetic materials, microsurgery, and advanced imaging for planning and guidance. They work closely with patients to understand their goals, provide comprehensive preoperative and postoperative care, and ensure the best possible outcomes.</p>
        <p>It is important to note that plastic surgery should be approached with realistic expectations and a thorough understanding of the risks and benefits. If you are considering plastic surgery, it is recommended to consult with a qualified and experienced plastic surgeon who can evaluate your specific situation, discuss your goals, and guide you through the decision-making process.</p>
    </div>
    </>)
}
export default memo(Thretyser)