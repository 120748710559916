import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { useState } from "react";
function Mobilemenu(props){
    const [truea, setTrue]=useState(true);
    const [show, setShow]=useState("none")
    const dischanger={
        display:`${props.mobcontrol}`
    }
    function menuchanger(){
        props.setMobcontrol("none")
    }
    window.addEventListener("resize",()=>{
        props.setMobcontrol("none")
    })
    function submenu(){
        if(truea===true){
            setShow("block");
            setTrue(false)
        }else{
            setShow("none");
            setTrue(true)            
        }
    }
    const showme={
        display:`${show}`
    }
    return (<>
    <div style={dischanger} className="mobheader">
        <div className="firstheaderbox backmob">
            <img src="../photo/sec-logo.jpg" alt="" />
            <img src="../photo/logo.jpg" alt="" />
        </div>
        <div>
            <ul className="mobmenu">
                <li><Link to="/" className="Menulike submenusubmob">Home</Link></li>
                <li><Link to="/about" className="Menulike submenusubmob">About Us</Link></li>
                <li><div className="sermenu"><Link to="/services" className="Menulike submenusubmob">Services</Link><span onClick={submenu} className="mainnamespin mobicopad"><img src="../photo/play.png" alt="" /></span></div>
                <ul style={showme} className="mobmenua">
                <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Advanced Minimally Access Surgeries")
                        }} className="Menulike submenusubmob">Advanced Minimally Access Surgeries</Link></li>
                <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Non Invasive Pinhole Endovascular Surgeries")
                        }} className="Menulike submenusubmob">Non Invasive Pinhole Endovascular Surgeries</Link></li>
                <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Plastic & Cosmetic Surgeries")
                        }} className="Menulike submenusubmob">Plastic & Cosmetic Surgeries</Link></li>
                <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Gastro Intestinal Surgeries")
                        }} className="Menulike submenusubmob">Gastro Intestinal Surgeries</Link></li>
                <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Orthopedic Surgery")
                        }} className="Menulike submenusubmob">Orthopedic Surgery</Link></li>
                <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Neurosurgery & Neurosciences")
                        }} className="Menulike submenusubmob">Neurosurgery & Neurosciences</Link></li>
                </ul>
                </li>
                <li><Link to="/contact" className="Menulike submenusubmob">Contact Us</Link></li>
            </ul>
        </div>
        <div className="mobfirst" onClick={menuchanger}>
            <h6>BACK</h6>
            <span className="mainnamespin mobicopad"><img src="../photo/play.png" alt="" /></span>
        </div>
    </div>
    </>)
}
export default Mobilemenu;