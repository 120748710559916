import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Spinesurgery(){
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Spine Surgery</h2>
                    <p>Spine surgery is a surgical specialty that focuses on the diagnosis, treatment, and management of conditions affecting the spine. It involves surgical procedures performed on the spine to alleviate pain, correct deformities, stabilize the spine, and restore function.</p>
                    <p>Spine surgeons are medical doctors who specialize in the surgical treatment of spine disorders. They undergo extensive training in orthopedic surgery or neurosurgery and further specialize in spine surgery through additional fellowship training.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Here are some common conditions that may require spine surgery.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/pp1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Herniated Disc</h6>
                            <p>When a disc in the spine ruptures or slips out of place, it can cause pressure on nearby nerves, resulting in pain, numbness, or weakness.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/pp2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Spinal Stenosis</h6>
                            <p>Spinal stenosis is a narrowing of the spinal canal that can cause compression of the spinal cord or nerves.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/pp3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Scoliosis</h6>
                            <p>Scoliosis is an abnormal curvature of the spine. Severe cases of scoliosis may require spinal fusion surgery, where the vertebrae are fused together to correct the curvature and stabilize the spine.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12 p-3">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Spinesurgery)