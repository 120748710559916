import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Threeser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a3.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>General Medicine</h5>
        <p>General medicine, also known as internal medicine or primary care, is a medical specialty that focuses on the comprehensive care of adults for a wide range of health issues. General medicine practitioners, called general physicians or internists, are trained to provide primary healthcare services, including preventive care, diagnosis, treatment, and management of various medical conditions.</p>
        <p>General medicine encompasses a broad spectrum of health concerns and conditions. Some of the common areas of focus within general medicine include:</p>
        <h6>Routine check-ups and preventive care:</h6>
        <p>General physicians perform regular check-ups to assess overall health, monitor vital signs, and screen for potential health risks. They provide guidance on healthy lifestyle choices, vaccinations, and preventive measures to maintain well-being.</p>
        <h6>Management of chronic conditions:</h6>
        <p>General physicians are trained to diagnose and manage chronic diseases such as diabetes, hypertension (high blood pressure), asthma, chronic obstructive pulmonary disease (COPD), and heart disease. They work with patients to develop treatment plans, monitor progress, and adjust medications as necessary.</p>
        <h6>Infectious diseases:</h6>
        <p>General physicians diagnose and treat various infectious diseases, such as respiratory infections, urinary tract infections, gastrointestinal infections, and skin infections. They may prescribe appropriate medications, order diagnostic tests, and provide guidance on infection prevention.</p>
        <h6>Gastrointestinal disorders: </h6>
        <p>General physicians address conditions related to the digestive system, including acid reflux, irritable bowel syndrome (IBS), gastritis, and inflammatory bowel disease (such as Crohn's disease and ulcerative colitis).</p>
        <h6>Respiratory conditions:</h6>
        <p>General physicians manage respiratory conditions such as asthma, chronic bronchitis, pneumonia, and chronic obstructive pulmonary disease (COPD). They may prescribe medications, recommend lifestyle modifications, and provide guidance on managing symptoms.</p>
        <p>General physicians use their knowledge and expertise to assess patients' symptoms, conduct physical examinations, order and interpret diagnostic tests, and develop personalized treatment plans. They may refer patients to specialists when necessary, coordinating overall care and managing multiple aspects of a patient's health.</p>
        <p>It's important to note that general medicine encompasses a wide range of medical conditions, and general physicians serve as the first point of contact for many patients. However, if a patient's condition requires specialized care, the general physician may refer them to an appropriate specialist for further evaluation and treatment.</p>
        <p>If you have specific medical concerns or conditions, it is recommended to consult with a qualified general physician or healthcare professional for a comprehensive evaluation and personalized care.</p>
    </div>
    </>)
}
export default memo(Threeser)