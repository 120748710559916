import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Pathology(){
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Pathology</h2>
                    <p>Pathology is a medical specialty that focuses on the study of disease, including the causes, mechanisms, and effects of diseases. Pathologists are medical doctors who specialize in pathology and play a crucial role in the diagnosis and understanding of various diseases.</p>
                    <p>Pathologists examine tissues, cells, and body fluids to identify abnormalities and provide important diagnostic information to guide patient care. They work in collaboration with other healthcare professionals, including primary care physicians, surgeons, and oncologists, to help determine the most appropriate treatment options for patients. </p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Here are a few areas of focus within pathology.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ll1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Anatomic Pathology</h6>
                            <p>Anatomic pathologists examine tissues obtained from biopsies, surgical specimens, or autopsies under a microscope.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ll2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Clinical Pathology</h6>
                            <p>Clinical pathologists analyze body fluids, such as blood, urine, and cerebrospinal fluid, to aid in the diagnosis and monitoring of diseases.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ll3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Molecular Pathology</h6>
                            <p>Molecular pathologists study the genetic and molecular changes that occur in diseases.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12 p-3">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Pathology)