import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Tenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a10.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Orthopaedics</h5>
        <p>Orthopedics, also known as orthopedic surgery, is a medical specialty that focuses on the diagnosis, treatment, and management of conditions and injuries affecting the musculoskeletal system. Orthopedic surgeons are medical doctors who specialize in orthopedics and provide care for patients with a wide range of musculoskeletal problems.</p>
        <p>The musculoskeletal system includes bones, joints, ligaments, tendons, muscles, and other supporting structures. Orthopedic surgeons are trained to address both congenital and acquired musculoskeletal conditions, as well as sports-related injuries and trauma. Some of the common areas of focus within orthopedics include:</p>
        <h6>Joint replacement surgery:</h6>
        <p>Orthopedic surgeons perform joint replacement surgeries, such as hip replacement, knee replacement, and shoulder replacement, to treat severe joint pain and dysfunction caused by conditions like osteoarthritis or rheumatoid arthritis.</p>
        <h6>Fracture management: </h6>
        <p>Orthopedic surgeons diagnose and treat fractures, which are broken bones. They may use various techniques to align and stabilize fractures, including casting, splinting, or surgical fixation with plates, screws, or rods.</p>
        <h6>Sports medicine:</h6>
        <p>Orthopedic surgeons with specialized training in sports medicine focus on diagnosing and treating sports-related injuries, including ligament tears (such as ACL or MCL tears), meniscus tears, and tendon injuries. They often work closely with physical therapists to facilitate recovery and help athletes return to their activities.</p>
        <h6>Spine surgery:</h6>
        <p>Orthopedic surgeons perform spine surgeries to treat conditions affecting the spine, such as herniated discs, spinal stenosis, spinal deformities (like scoliosis), and spinal fractures. They may perform procedures such as spinal fusion, laminectomy, or discectomy to alleviate pain and restore spinal stability.</p>
        <p>Orthopedic surgeons utilize a variety of diagnostic techniques, including imaging studies like X-rays, MRI scans, or CT scans, to assess the musculoskeletal system and determine the most appropriate treatment options. Treatment plans may include conservative measures such as physical therapy, medications, and assistive devices, as well as surgical interventions when necessary.</p>
        <p>Orthopedic surgeons often work in multidisciplinary teams, collaborating with other healthcare professionals such as physical therapists, occupational therapists, pain management specialists, and rehabilitation specialists to provide comprehensive care to their patients. They may also provide education and preventive care to promote musculoskeletal health and prevent injuries.</p>
        <p>If you have concerns related to the musculoskeletal system or require orthopedic care, it is recommended to consult with a qualified orthopedic surgeon or healthcare professional for an accurate diagnosis and personalized treatment plan based on your specific needs.</p>
    </div>
    </>)
}
export default memo(Tenser)