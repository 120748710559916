import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Pulmonology(){
    return (<>
    
    <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Pulmonology</h2>
                    <p>Pulmonology is a medical specialty that focuses on the diagnosis, treatment, and management of diseases and conditions that affect the respiratory system, specifically the lungs and the airways. Physicians who specialize in pulmonology are called pulmonologists or pulmonary medicine specialists.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Pulmonologists are trained in the evaluation and management of a wide range of respiratory conditions, including.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/qq1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Asthma</h6>
                            <p>Pulmonologists diagnose and manage asthma, a chronic condition characterized by inflammation and narrowing of the airways.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/qq2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Chronic Obstructive Pulmonary Disease (COPD)</h6>
                            <p>COPD is a progressive lung disease that includes chronic bronchitis and emphysema.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/qq3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Respiratory Infections</h6>
                            <p>Pulmonologists diagnose and treat various respiratory infections, including pneumonia, bronchitis, and tuberculosis. They determine the appropriate antibiotic or antiviral treatments and monitor patients' response to therapy.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/qq4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Interstitial Lung Diseases</h6>
                            <p>Pulmonologists specialize in the diagnosis and management of interstitial lung diseases (ILD), a group of lung disorders characterized by inflammation and scarring of the lung tissue.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/qq5.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Sleep-Related Breathing Disorders</h6>
                            <p>Pulmonologists evaluate and treat sleep-related breathing disorders, such as obstructive sleep apnea.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12 p-3">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Pulmonology)