import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function NineSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b14.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>The year 2020 will be written in human history as one of the most lethal years since the entire world is reeling under the violent attack of the deadliest yet mysterious corona virus. Ever since December last, the virus has spread all over the globe claiming millions of lives. Young or old, the virus spared none. Corona virus being new, its nature and action are completely unknown to the scientists. It keeps on mutating every other day posing new challenges to the scientists to study and identify its real nature. So Covid 19 treatment is absolutely unknown to the doctors across the globe. Even after a year of its huge spread doctors are groping in the dark to find specific treatment for Covid 19.</p>
        <h5>Certain Preventive Measures</h5>
        <p>It is always said that prevention is better than cure. While the specific Covid 19 treatment is still at bay, it is wiser to follow the preventive measures to protect yourself from Covid 19. Corona Virus spreads primarily through droplets when an infected person coughs sneezes or speaks. By touching a contaminated surface with your hands and thereafter using the same hand to rub your eyes or nose, you can contaminate yourself with the virus. So the best way to keep yourself safe from Covid 19 is to avoid all kinds of contamination.</p>
        <p>According to WHO (World Health Organisation) guideline always wear a mask to avoid contamination. It protects you from exhaling the contaminated air and inhaling the same from others. Masks can be non surgical cloth masks, N95 masks, and “use and throw” masks. Wear the mask to cover your mouth and nose wholly. For cloth mask, it is mandatory to wash the mask with soap after every use. If you use “use and throw mask”, always throw it in a covered bin after each use. These masks are meant for one time use. So do not use these masks repetitively. Change your surgical mask after 4-6 hours use. If a mask is torn, loose or create fog in your glass, take care to replace it immediately.</p>
        <p>Maintaining social distance is one of the most important ways to avoid contamination. It is advised to maintain a distance of 2ft with other people when you go out for any work or business. Corona virus can also spread from people who are asymptomatic. So distancing is essential to keep yourself safe from Covid 19. Stay away from people who are showing symptoms of flu or fever within or outside your home.</p>
        <p>Washing hands with soap or alcohol based sanitizer protects you from contamination. Whenever you go out or touch anything outside, take care to sanitize your hands. Returning home from work, wash your hands and feet with soap or else take a bath.</p>
        <p>At the time of sneezing or coughing it is better to use a tissue paper. If you do not have a tissue paper, use your arm to cover your mouth and nose. It is advised not to use your palm to cover your mouth while coughing or sneezing.</p>
        <p>If you experience symptoms of fever, dry cough, tastelessness, absence of smell or diarrhoea, immediately consult a doctor or contact local health centre.</p>
        <p>To fight against diseases, it is wiser to boost your immunity. Include protein rich foods like fish, meat, egg, milk, pulses, nuts, and vegetables in your diet to increase your immunity and make yourself strong from within.</p>
    </div>
    </>)
}
export default memo(NineSub)