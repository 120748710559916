import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function SixSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b5.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Ever since Covid-19 pandemic had broken out in the world, the developed and developing countries like India engage themselves in preparing its vaccine to protect the lives of crores of citizens spreading across the globe. Thanks to the researchers that they have come up with several vaccines towards the end of the year 2020, which is believed to provide safe guard against the deadly corona virus. With the immunisation process kick-started, people vulnerable to the dangers of the pandemic, a deadlier wave of infection, have started to see a silver lining before them.</p>
        <h5>Types of Covid Vaccines</h5>
        <p>So far all the developed and developing countries have come up with their vaccines that ensure above 90% efficacy against corona virus. According to WHO report towards the end of December 2020, around 200 vaccines were on the pipeline and of these different types of Covid vaccines, about 52 are used for human trials.</p>
        <p>Names of some of the effective Covid vaccines include: Covishield (Oxford-Astrazeneca), Covaxin (Bharat Biotech), Pfizer BioNtech, Moderna, Sputnik V and Johnson & Johnson’s Janssen.

Many other vaccines are on the trial run stage. When these vaccines will prove their efficacy, the number of vaccines will increase further.</p>
<h5>Covid Vaccine in India</h5>
<p>India is a powerful and potential contender of preparing vaccine for Covid 19. Serum Institute India and Bharat Biotech are the two major manufacturer of Covid vaccine in India. Serum Institute, the largest manufacturer of vaccines in India had joined hand with Oxford-Astrazeneca to manufacture Covishield, an effective vaccine for Covid 19. Covishield is called a viral vector vaccine that provides prevention against COVID-19. The two doses of the vaccine aim at protecting the recipient from corona virus while boosting up the immunogenicity. The two shots of Covishield are administered at a gap of 6-8 weeks.</p>
<p>India’s indigenous COVID-19 vaccine, known as Covaxin is developed and manufactured by Bharat Biotech in collaboration with the Indian Council of Medical Research (ICMR) – National Institute of Virology (NIV). Apart from providing protection from corona virus, the two doses of Covaxin act as immune-potentiators that increase and boost immunity power. The gap between two shots of Covaxin is 4-6 weeks.

Both Covishield and Covaxin are tested and trialled and prove more than 80% efficacy.</p>
<h5>Side Effects of Covid Vaccine</h5>
<p>T and B lymphocytes cells of blood recognize the targeted virus and produce antibodies to combat it. Vaccines injected into the body activate T and B lymphocytes for this purpose. As the vaccines improve immunity, it may result in certain side effects. The side effects of Covid vaccines are peaked within 24 hours of receiving the vaccine and may continue for 1-2 days. The common symptoms of side effects include:Pain or tenderness at the injection siteHeadacheTirednessMuscle or joint achesFeverChillsNausea</p>
<p>Besides, a person may feel swelling, pain, redness, an itchy rash, and other mild forms of irritation at the vaccine site which is usually the upper arm.

Such minor Covid vaccine symptoms are not much cause of concern. These usually go away after taking paracetamols as prescribed by the doctors.</p>
<h5>Best Covid Vaccine in India</h5>
<p>Following the guidelines of ICMR, Indians are vaccinated with either Covishield or Covaxin. So far both the vaccines are yielding effective results.

Until now as per Covid vaccine update available, about 16.7Cr Indians have been vaccinated. Of them 3.41 Cr people, which amounts to be 2.5% of total population, have been fully vaccinated. With the vaccine supply dwindling, India’s vaccination drive is staggering. In many states, the vaccine drive among the youths from 18 years cannot be started due to this shortage of vaccine supply.</p>
    </div>
    </>)
}
export default memo(SixSub)