import { memo, useContext } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"
import { data3 } from "../../App";

function Blogsecondbox(){
    const changers=useContext(data3);
    const menu=[
        {
            img:"../photo/b7.jpg",
            name:"Doctors to be Consulted for Back Pain",
            content:"Diabetes commonly known as life style disorder often leaves its toll upon the mental health of people. People detected with diabetes have to monitor their blood..",
        },
        {
            img:"../photo/b8.jpg",
            name:"The Most Common Diseases of Childhood",
            content:"What exactly is a psychiatric disorder? Psychological illnesses, often known as mental disorders, are mental imbalances that lead to persistent specific behavio..",
        },
        {
            img:"../photo/b9.jpg",
            name:"Different Types of Spine Surgery",
            content:"Cardiac arrest is a medical condition occurs due to sudden cessation of heart functioning. The function of heart is to send oxygenated blood to the body. Cardia..",
        },
        {
            img:"../photo/b10.jpg",
            name:"Teenage Health Problems and How to Deal with Them",
            content:"Modern life being fast and full of stress leaves its toll upon human beings. In most of the metropolitan cities people are suffering from diseases like diabetes..",
        },
        {
            img:"../photo/b11.jpg",
            name:"Accredited Surgical Facility",
            content:"Ever since Covid-19 pandemic had broken out in the world, the developed and developing countries like India engage themselves in preparing its vaccine to protec..",
        },
    ]
    return (<>
    <section className="mb-0">
        <div className="container">
                <div style={{textAlign:"center"}} className="mediaheader mb-4">
                    <h6>MEDIA CENTER</h6>
                    <h1>latest Updates & Articles</h1>
                </div>
            <div className="row">
            {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div style={{height:"auto"}} className="col-lg-6 col-12 mb-4">
                                        <div style={{backgroundColor:"#dbeafe", height:"100%"}}>
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="chafirst">
                                            <h5>{name}</h5>
                                            <Link to="/subblog" onClick={()=>{
                            changers(name)
                        }} className="noreinfo absuinfo">MORE INFO</Link>
                                        </div>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Blogsecondbox);