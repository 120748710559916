import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Twoser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a2.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Dermatology</h5>
        <p>Dermatology is a medical specialty that focuses on the diagnosis, treatment, and prevention of conditions related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide care for patients with a wide range of skin conditions.</p>
        <p>The skin is the largest organ of the body and serves as a protective barrier against the external environment. Dermatologists are trained to diagnose and treat various skin disorders, including:</p>
        <h6>Dermatitis and eczema:</h6>
        <p>These conditions involve inflammation of the skin, resulting in symptoms such as redness, itching, and rash. They can be caused by allergens, irritants, genetic factors, or immune system dysfunction.</p>
        <h6>Acne:</h6>
        <p>Acne is a common skin condition that occurs when hair follicles become clogged with oil and dead skin cells. It can range from mild to severe and may cause pimples, blackheads, and whiteheads.</p>
        <h6>Psoriasis:</h6>
        <p>Psoriasis is a chronic autoimmune disease that causes the rapid buildup of skin cells, resulting in thick, red, scaly patches. It can affect any part of the body and may be associated with joint pain in some cases.</p>
        <h6>Skin infections:</h6>
        <p>Dermatologists diagnose and treat various bacterial, viral, and fungal infections that affect the skin. Examples include impetigo, warts, cold sores, athlete's foot, and fungal nail infections.</p>
        <h6>Skin cancer:</h6>
        <p>Dermatologists play a crucial role in the early detection and treatment of skin cancer. They perform skin examinations, biopsy suspicious lesions, and provide appropriate management for different types of skin cancer, including melanoma, basal cell carcinoma, and squamous cell carcinoma.</p>
        <h6>Hair and nail disorders:</h6>
        <p>Dermatologists also evaluate and manage conditions affecting the hair and nails. This includes hair loss (alopecia), scalp infections, dandruff, nail infections, and nail abnormalities.</p>
        <p>Dermatologists utilize various diagnostic techniques and tools, such as visual examination, dermoscopy (a magnifying tool for skin examination), skin biopsies, allergy testing, and patch testing. They may prescribe medications, topical creams, perform surgical procedures, or recommend cosmetic treatments for specific conditions.</p>
        <p>In addition to treating skin conditions, dermatologists also emphasize the importance of skin protection and provide guidance on skincare routines, sun protection, and the prevention of skin damage. They may also offer cosmetic procedures such as Botox, dermal fillers, chemical peels, and laser treatments to address aesthetic concerns.</p>
        <p>If you have specific concerns about your skin, hair, or nails, it is recommended to consult with a qualified dermatologist or healthcare professional for an accurate diagnosis and appropriate treatment plan tailored to your needs.</p>
    </div>
    </>)
}
export default memo(Twoser)