import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function NineSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b9.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Spine may get injured due to various reasons like trauma, structural anomalies, sports injury, ageing and improper body mechanics. All these conditions require special treatment. Often a sheer fall from stairs and high places results in severe back pain that trouble people throughout their lives. There may be other reasons that attribute to back pain. But whatever may be the cause all spine problems need to be addressed properly. With proper treatment and medical guidance all sorts of spine ailments can be cured and the patients can get back their normal life style and carry on with the desired physical activities.</p>
        <p>Doctors recommend spine surgery judging the severity and condition of the spine problem. In case of herniated disc, scoliosis, or spinal Stenosis surgery is highly recommended. Spine surgery can be open and minimally invasive type.</p>
        <h6>Open Spinal Surgery:</h6>
        <p> During open spinal surgery, the surgeon opens the operative site with a large incision and can view the entire spinal anatomy.</p>
        <h6>Minimally Invasive Spine Surgery: </h6>
        <p>With advanced technology, spine surgery is done involving minimally invasive procedures. This procedure entails a small incision on the affected region and reduces the intra-operative manipulation of soft tissues. Thus the procedure curtails the duration of surgery and quickens the recovery process. The patients feel less pain also.</p>
        <h5>Different types of spine surgery are as follows:</h5>
        <h6>Vertebroplasty and Kyphoplasty:</h6>
        <p>These procedures involve the repair compression fractures of the vertebrae due to osteoporosis.</p>
        <h6>Spinal Laminectomy/spinal decompression:</h6>
        <p>This is done to improve the condition caused by spinal Stenosis or narrowing of the spinal canal resulting in pain, numbness or weakness.</p>
        <h6>Discectomy: </h6>
        <p>A herniated disc and compressed nerve root or the spinal cord are operated using this procedure.</p>
        <h6>Foraminotomy: </h6>
        <p>his procedure involves enlargement of the bony hole where a nerve root exits the spinal canal to prevent bulging discs or joints thickened with age from pressing on the nerve.</p>
        <h6>Nucleoplasty or Plasma Disc Decompression:</h6>
        <p>This is a laser surgery that uses radiofrequency energy to treat low back pain associated with a mildly herniated disc.</p>
        <h6>Spinal Fusion:</h6>
        <p>During Spinal Fusion the surgeon removes the spinal disc between two or more vertebrae and fuses the adjacent vertebrae using bone grafts or metal devices secured by screws.</p>
    </div>
    </>)
}

export default memo(NineSub)