import { memo, useContext } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { data4 } from "../../App"
import { data5 } from "../../App"

function Newcontactsesctor(props){
    
    const service=useContext(data4)
    const services=useContext(data5)
    const stayler={
        display:`${service}`
    }
    function changer(){
        services("none")
    }
    window.addEventListener("resize",()=>{
        if(window.innerWidth<768){
            services("none")
        }
    })
    return (<>
        <div style={stayler} className="mobconform">
    <div>
    <div className="innerform innermobcon">
        <div className="row contitle">
            <h5>Contact Us</h5>
        </div>
        <div>
            <label><h6>Your Name</h6></label><br></br>
            <input className="inconlas"></input><br></br>
            <label><h6>Your Email</h6></label><br></br>
            <input className="inconlas"></input><br></br>
            <label><h6>Phone Number</h6></label><br></br>
            <input className="inconlas"></input><br></br>
            <label><h6>Message</h6></label><br></br>
            <textarea className="meassageconfor" name="" id="" cols="30" rows="10"></textarea>
        </div>
        <div className="subconfor">
            <button className="subformbox">Submit</button>
        </div>
    </div>
    <div onClick={changer} className="errorbox">
        <LazyLoadImage src="../photo/error.png"></LazyLoadImage>
    </div>
    </div>
    </div>
    </>)
}
export default memo(Newcontactsesctor)