
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from 'react-lazy-load';

function Abouthospital(){
    return (<>
            <div className="section">
            <div className="container">
                <div className="tittle">
                    <h4>About Us</h4>
                    <h2>Best multispeciality hospital in south bangalore</h2>
                </div>
                <div className="row">
                    <div className="col-xl-6 firstbox">
                        <div>
                        <div className="mainname mb-4">
                             <h5>“Experience exceptional care with Adhventha Hospital – where compassion meets expertise in medical diagnostics!”</h5>
                        </div>
                        <p>We understand that healthcare is not just about treating illnesses, it's about providing compassionate care and cutting-edge medical solutions that cater to the holistic well-being of our patients. Our unwavering commitment to patient-centricity and state-of-the-art technology sets us apart as a leading multispecialty hospital.</p>
                        <p>Located in the heart of Bangalore, Adhventha Hospital is a renowned multispecialty hospital that brings together a team of expert doctors, skilled medical professionals, and advanced machinery to offer a comprehensive range of healthcare services under one roof. With Years of Experience and a passion for excellence, we have earned a reputation for delivering top-notch medical care across various specialties.</p>
                        </div>
                    </div>
                    <LazyLoad className="col-xl-6 firstbox">
                        <img src="../photo/real-img-one.jpg" alt="" />
                    </LazyLoad>
                </div>
            </div>
        </div>
    </>)
}
export default Abouthospital;