import Mainservice from "../MainService";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"

function Mainserviceser(){
    return (<>
    <div className="section">
        <Mainservice/>
    </div>
    </>)
}
export default Mainserviceser;