import { memo } from "react"
import LazyLoad from "react-lazy-load"

function OneSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b6.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <h5>What Conditions does a urologist treat?</h5>
        <p>If anything really bad happens with your urinary system or your body’s mechanism for trying to get rid of pee, you may have to see a urologist. They are professionals who can treat everything from kidney stones to cancer.</p>
        <h5>What Exactly Is a Urologist?</h5>
        <p>The urologist is well-versed in the urinary system, which involves your kidneys, bladder, ureters (thin muscles that transport urine into your bladder), and urethra (the tube that drains pee out of your bladder.)

Urologists also care for men’s reproductive organs, which also include the penis, testes, scrotum, and prostate.</p>
<h6>A urologist might be your primary care physician if you have:</h6>
<p>1) Kidney stones are a common issue.</p>
<p>2) Cancer of the prostate.</p>
<p>3) Long-term urinary retention issues.</p>
<h5>What Conditions does a urologist treat?</h5>
<p>Urologists can help with bladder issues, urinary infections (UTIs), bladder and kidney cancer, kidney obstruction, and kidney stones.</p>
<h6>Men may also notice them for:</h6>
<p>1) ED (erectile dysfunction) (ED).</p>
<p>2) Prostate gland enlargement.</p>
<p>3) Cancer of the prostate.</p>
<p>4) Cancer of the testicles.</p>
<h6>Women may also consult a urologist for</h6>
<p>Having difficulty retaining your pee after pregnancy.
Prolapse of the pelvic organs (when an organ like the uterus or bladder starts to drop or press into or out of the vagina).</p>
<p>
If a child has an atypical urinary tract condition, such as bedwetting, he or she may need to consult a urologist.</p>
<h5>What Should I Expect from a Visit?</h5>
<p>On your first visit urologist may ask for information about your medical history, present symptoms, and medications you’re taking.

The urologist will perform a physical exam, which will include a genital and rectal examination. They may also request blood tests or imaging tests, such as a CT scan or ultrasound, to get a better look at your organs.</p>
    </div>
    </>)
}
export default memo(OneSub)