import { memo, useContext, useEffect, useState } from "react"
import Newservices from "../NewSections/Newservices";
import Newserbanner from "./Newserbanner";
import Headertwo from "../NewSections/Headertwo";
import Newmainser from "./Newmainser";
import Newfooter from "../NewSections/Newfooter";
import Headernewtop from "../NewSections/Headernewtop";
import serv from "./Newserinfo"
import { data } from "../../App"
import Mobcontroller from "../ContactButton/Mobcontroller";
import Contactbutton from "../ContactButton/Contactbutton";
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo";
import Newservicebanner from "../Newservicesbox/Newservicebanner";
import { Outlet } from "react-router-dom";
import Newcontactin from "../NewSections/Newcontactin";
import Newdoctorlist from "../Ourdoctor/Newdoctorlist";
import Newcontactsescter from "../NewSections/Newcontactsescter";
import Contactinforlink from "../Newservicesbox/Contactinforlink";
import Functionmain from "../Functionmain";
import Servicebannerinfo from "../Newservicesbox/Servicebannerinfo";

function Serviceinfo(){
    const service=useContext(data)
    const [checher, setCheck]=useState(serv)
    return (<>
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
        <Headernewtop/>
    <Outlet/>

        {/* {
                        checher.map((elem)=>{
                            const {nmae}=elem
                            {
                                return (<>
                                    <Newserbanner nmae={nmae}/>
                                </>)
                            }
                        })
                    } */}
                    <Contactinforlink/>
    {/* <Newmainser/> */}
    
    <Newfooter/>
    </>)
}
export default memo(Serviceinfo);