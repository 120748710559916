import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import { useEffect, useState } from "react";
function Headmenu(props){
    const [posfix, setPosfix]=useState("relative")

    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY>106){
                setPosfix("fixed")
            }else{
                setPosfix("relative") 
            }
        })
    })
    const stylechanger={
        position:`${posfix}`
    }

    function menudisplay(){
        props.setMobcontrol("block");
    }

    return (<>
    <div style={stylechanger} className="header">
        <div className="headerf">
        <div className="headone">
            <h5>Adhventha Hospital</h5>
        </div>
        <div className="headsec">
            <ul className="Menu-top">
                <li><Link to="/" className="Menulike">HOME</Link></li>
                <li><Link to="/about" className="Menulike">ABOUT US</Link></li>
                <li><Link to="/contact" className="Menulike">OUR DOCTORS</Link></li>
                <li><Link to="/services" className="Menulike">DEPARTMENTS</Link>
                    <ul className="submenu">
                        <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Advanced Minimally Access Surgeries")
                        }} className="Menulike submenusub">Advanced Minimally Access Surgeries</Link></li>
                        <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Non Invasive Pinhole Endovascular Surgeries")
                        }}  className="Menulike submenusub">Non Invasive Pinhole Endovascular Surgeries</Link></li>
                        <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Plastic & Cosmetic Surgeries")
                        }}  className="Menulike submenusub">Plastic & Cosmetic Surgeries</Link></li>
                        <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Gastro Intestinal Surgeries")
                        }}  className="Menulike submenusub">Gastro Intestinal Surgeries</Link></li>
                        <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Orthopedic Surgery")
                        }}  className="Menulike submenusub">Orthopedic Surgery</Link></li>
                        <li><Link to="/Hospitalservice" onClick={()=>{
                            props.setSerselect("Neurosurgery & Neurosciences")
                        }}  className="Menulike submenusub">Neurosurgery & Neurosciences</Link></li>
                    </ul>
                </li>
                <li><Link to="/contact" className="Menulike">BLOG</Link></li>
                <li><Link to="/contact" className="Menulike">GALLERY</Link></li>
                <li><Link to="/contact" className="Menulike">CONTACT US</Link></li>
            </ul>
        </div>
        </div>
        {/* <div className="headerthree">
            <ul className="headicon">
                <li><a href=""><span className="mainnamespin headicopad"><img src="../photo/facebook-app-symbol.png" alt="" /></span></a></li>
                <li><a href=""><span className="mainnamespin headicopad"><img src="../photo/social (1).png" alt="" /></span></a></li>
                <li><a href=""><span className="mainnamespin headicopad"><img src="../photo/instagram (6).png" alt="" /></span></a></li>
                <li><a href=""><span className="mainnamespin headicopad"><img src="../photo/google-plus-logo.png" alt="" /></span></a></li>
            </ul>
        </div> */}
        <div onClick={menudisplay} className="headfour">
            <div className="menubutton">
                <img src="../photo/menu (2).png" alt="" />
            </div>
        </div>
    </div>
    </>)
}
export default Headmenu;