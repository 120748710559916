import { memo } from "react";
import LazyLoad from "react-lazy-load";

function Contactinfolink() {
  return (
    <>
      <section>
        <div className="continer bakcolorer pb-0 pt-2 mt-0 ">
          <div className="centerlinnk erdoctertr h-100">
            <div className="col-xl-6 col-md-7 llliner mt-0">
              <div className="adhresss mt-0">
                <h1>Adhventha Hospital</h1>
                <h4>
                  Please contact us with your question and needs, our team would
                  be delighted to assist you promptly
                </h4>
                <div className="danger">
                  {/* <h4>Approved By</h4>
                        <LazyLoad className="iiii">
                            <img src="../../photo/ABHAlogoWhite.png" alt="" />
                        </LazyLoad> */}
                  <a
                    style={{ textDecoration: "none" }}
                    href="tel:9342595404"
                    className="bookappoint apppp"
                  >
                    <img
                      className="w-100er"
                      height={"20px"}
                      src="../../photo/call.png"
                      alt=""
                    />
                    9342595404
                  </a>
                </div>
                <div className="hinnerqwer">
                  {/* <div>
                        <LazyLoad className="ininin">
                            <img src="../../photo/aa.png" alt="" />
                        </LazyLoad>
                    </div>
                    <div>
                        <LazyLoad className="ininin">
                            <img src="../../photo/bb.png" alt="" />
                        </LazyLoad>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="rowsssss indexer">
            <div className="col-md-3 h-100 t-5 alimerdoctor">
              <LazyLoad className="namersy namersya centerlia">
                <img className="" src="../photo/post.png" alt="" />
              </LazyLoad>
            </div>
            <div className="col-md-3 alincenter pb-4">
              <LazyLoad className="namersy rwe-60">
                <img src="../../photo/Framelogo.png" alt="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Contactinfolink);
