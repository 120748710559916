import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function ThreeSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b3.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Cardiac arrest is a medical condition occurs due to sudden cessation of heart functioning. The function of heart is to send oxygenated blood to the body. Cardiac arrest is caused malfunctioning of heart’s electrical system. Due to the malfunctioning of heart’s electrical system, the hearts’ pumping system gets disrupted resulting in the stop of blood flow. During cardiac arrest a person loses consciousness suddenly and even may die for lack of immediate treatment.</p>
        <h5>Symptoms of Cardiac Arrest</h5>
        <h6>Although cardiac arrest occurs suddenly, it shows certain symptoms which include:</h6>
        <p>1) Sudden collapse</p>
        <p>2) Loss of consciousness</p>
        <p>3) Loss of breathing</p>
        <h6>Often people experience certain symptoms before a complete cardiac arrest which are:</h6>
        <p>1) Difficulty in breathing</p>
        <p>2) Feeling dizzy</p>
        <p>3) Chest pain</p>
        <h5>Causes of Cardiac Arrest</h5>
        <p>Abnormal heart beating (arrhythmia) due to malfunctioning of heart’s electrical system causes cardiac arrest. The heart rate and rhythm is controlled by heart’s electrical system. Often heart beats irregularly which lasts for sometime and is considered harmless. But arrhythmia which occurs in the lower chamber of heart (ventricle) causes cardiac arrest. During cardiac arrest rapid, erratic electrical impulses force ventricles quiver uselessly instead of pumping blood (ventricle fibrillation).</p>
        <h6>Cardiac arrest also occurs due to certain heart ailments. These are:</h6>
        <p>Coronary artery disease: People who have coronary artery disease suffer from cardiac arrest. When the arteries become clogged with cholesterol and other deposits, blood flow to the heart reduces and causes coronary artery disease.</p>
        <p>Heart attack: Often a heart attack due to severe coronary artery disease causes ventricular fibrillation and sudden cardiac arrest. The scar tissue after a heart attack leads to abnormalities in heart rhythm.</p>
        <p>Enlarged heart (Cardiomyopathy): When heart’s muscular walls stretch and enlarge or thicken, it is called Cardiomyopathy which also causes arrhythmias.</p>
        <p>Valvular heart disease: Valvular heart disease increases the risk of developing arrhythmia.</p>

    </div>
    </>)
}
export default memo(ThreeSub)