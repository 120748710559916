import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Nephrology(){
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Nephrology</h2>
                    <p>Nephrology is a medical specialty that focuses on the diagnosis, treatment, and management of conditions related to the kidneys and urinary system. Nephrologists are medical doctors who specialize in nephrology and are trained to provide care for patients with kidney diseases and disorders.</p>
                    <p>The kidneys play a crucial role in maintaining the body's fluid balance, filtering waste products from the blood, regulating electrolyte levels, and producing hormones that control blood pressure and red blood cell production.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Nephrologists are responsible for evaluating and managing a wide range of kidney-related conditions, including.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/gg1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Chronic kidney disease</h6>
                            <p>Nephrologists are involved in the diagnosis, staging, and management of CKD, which is a progressive loss of kidney function over time.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/gg2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Acute kidney injury (AKI)</h6>
                            <p>Nephrologists evaluate and manage sudden and temporary loss of kidney function, which can be caused by various factors such as severe infections, dehydration, medications, or trauma.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/gg3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Kidney stones</h6>
                            <p>Nephrologists diagnose and manage kidney stones, which are hard mineral and salt deposits that form in the kidneys.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/gg4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Hypertension (high blood pressure)</h6>
                            <p>Nephrologists specialize in the management of hypertension, particularly when it is associated with kidney disease. They work to control blood pressure levels and prevent further damage to the kidneys.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/gg5.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Glomerular diseases</h6>
                            <p>Nephrologists evaluate and manage conditions that affect the glomeruli, which are the filtering units of the kidneys.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Nephrology)