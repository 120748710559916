import { useEffect } from "react";
import Displayflopup from "./Displayflopup";

export function Displayflopupa({bodyes,hello, img,name,threea,setHello,setOne,setTwo,setThree,setThreea,num,servicer}){
    if(window.innerWidth>1200){
    let num3=(3*(Math.floor((servicer-1)/3)))
    if(num3>hello&&num3>num){
        if((num+1)%3===0||(num+1)===servicer){
        if(num===hello||(num-1)===hello||(num-2===hello)){
            return (<>
            <Displayflopup bodyes={bodyes} img={img} name={name} classone={"classone"} hello={hello}  threea={threea} setHello={setHello} num={num} setOne={setOne} setTwo={setTwo} setThree={setThree} setThreea={setThreea}/>
            </>)
            
        }
    }
    }
    if(num3<=hello&&num3<=num){
        if((num+1)%3===0||(num+1)===servicer){
        if(num===hello||(num-1)===hello||(num-2===hello)){
            return (<>
            <Displayflopup bodyes={bodyes} img={img} name={name} classone={"classone"} hello={hello}  threea={threea} setHello={setHello} num={num} setOne={setOne} setTwo={setTwo} setThree={setThree} setThreea={setThreea}/>
            </>)
            
        }
    }
    }
        
}else if(window.innerWidth>768){
    console.log(2*Math.floor((servicer-1)/2));
        let num4=(2*Math.floor((servicer-1)/2))
        if(num4>hello&&num4>num){
            if((num+1)%2===0||(num+1)===servicer){
                if(num===hello||(num-1)===hello){
                    console.log("hello");
                    return (<>
                        <Displayflopup bodyes={bodyes} img={img} name={name} classone={"classthree"} hello={hello} threea={threea} setHello={setHello} num={num} setOne={setOne} setTwo={setTwo} setThree={setThree} setThreea={setThreea}/>
                    </>)
                }
            }
        }
        if(num4<=hello&&num4<=num){
            if((num+1)%2===0||(num+1)===servicer){
                if(num===hello||(num-1)===hello){
                    console.log("hello");
                    return (<>
                        <Displayflopup bodyes={bodyes} img={img} name={name} classone={"classthree"} hello={hello} threea={threea} setHello={setHello} num={num} setOne={setOne} setTwo={setTwo} setThree={setThree} setThreea={setThreea}/>
                    </>)
                }
            }
        }
}else{
    if(num===hello){
        return (<>
        <Displayflopup bodyes={bodyes} img={img} name={name} classone={"classthree"} hello={hello} threea={threea} setHello={setHello} num={num} setOne={setOne} setTwo={setTwo} setThree={setThree} setThreea={setThreea}/>
        </>)
    }
}
}


    