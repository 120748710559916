const bannercontent=[
    {
        one:"“Discover unparalleled care at Adhventha Hospital, where the fusion of compassion and medical expertise sets the standard for exceptional diagnostics!”",
        two:"At Adhventha Hospital, we pride ourselves on our commitment to providing our patients with exceptional medical diagnostic services. Our team is comprised of highly experienced and specialized doctors, certified technologists, and compassionate support staff who are dedicated to delivering the highest quality imaging, laboratory, and comprehensive health check services with the utmost respect and courtesy.",
        page:"about",
    },
    {
        one:"“Immerse yourself in the pinnacle of care at Adhventha Hospital, where compassion fuses with medical mastery and advanced technology, crafting an extraordinary healthcare experience.”",
        two:"At Adhventha Hospital, we're dedicated to exceptional medical diagnostics. With experienced doctors, certified technologists, and compassionate staff, we offer top-quality imaging, lab, and health checks with respect and care.",
        page:"doctor",
    },
    {
        one:"“Encounter unparalleled care at Adhventha Hospital, where compassion intersects with medical diagnostic expertise for an exceptional experience!”",
        two:"Adhventha Hospital is dedicated to exceptional medical diagnostics. Our team includes skilled doctors, certified technologists, and compassionate support staff, committed to delivering top-quality imaging, lab services, and comprehensive health checks with respect and care.",
        page:"gallery",
    },
    {
        one:"“Embark on a journey of exceptional care at Adhventha Hospital, where the synergy of compassion and medical diagnostic expertise ensures an unmatched experience!”",
        two:"Adhventha Hospital stands devoted to offering remarkable medical diagnostic services. Our team, consisting of adept doctors, certified technologists, and empathetic support staff, is wholeheartedly devoted to delivering top-tier imaging, lab, and comprehensive health check services with utmost respect and courtesy.",
        page:"contact",
    },
]
export default bannercontent