import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function OneNineSub(){
    return (<>
    <div style={{display:"flex",justifyContent:"center"}} className="mb-3 flex justify-center">
        <LazyLoad>
            <img style={{maxWidth:"400px"}} src="../photo/ss10.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
    <h3>Bangalore Leads the Way with Cryo Laser and Cryo Sclerotherapy (CLaCS)</h3>
    <p>Bangalore has always been at the forefront of medical advancements, and once again, the city is making headlines with the introduction of <strong>Cryo Laser and Cryo Sclerotherapy (CLaCS)</strong> at <strong>Adhventha Hospital</strong>. This innovative treatment method, a first for Karnataka and only the third in India, is brought to patients by the renowned <strong>Dr. Bhaskar MV</strong>, a vascular specialist with over 20 years of expertise in treating vein-related conditions.</p>

    <h5>What is CLaCS?</h5>
    <p>CLaCS is a cutting-edge technique for treating <strong>varicose veins</strong> and <strong>spider veins</strong>, conditions that often lead to discomfort, cosmetic concerns, and potential complications if left untreated. Unlike conventional treatments, CLaCS combines the benefits of laser therapy and sclerotherapy with the application of cooling technology to deliver an efficient, minimally invasive, and virtually painless experience for patients.</p>

    <h5>Dr. Bhaskar MV: A Pioneer in Vascular Health</h5>
    <p>Dr. Bhaskar MV, who spearheads this revolutionary service, has been a pioneer in vascular care and is recognized for his contributions to improving vein health in India. With a legacy of excellence spanning two decades, his commitment to adopting advanced technologies ensures that patients in Karnataka now have access to world-class care without the need to travel far from home.</p>

    <h5>The Significance of CLaCS</h5>
    <p>The launch of CLaCS at Adhventha Hospital marks a significant milestone in the realm of vascular treatments. Traditionally, managing varicose veins involved invasive surgeries, lengthy recovery times, and significant patient discomfort. However, the advent of CLaCS has completely transformed this landscape.</p>
    <p>By combining a <strong>transdermal laser</strong> with <strong>sclerotherapy</strong>—where a solution is injected to close off faulty veins—and utilizing cooling techniques to reduce pain and swelling, the procedure is precise, effective, and patient-friendly.</p>

    <blockquote>
        <p>“CLaCS has revolutionized the way we treat varicose veins. It’s quick, requires no hospitalization, and allows patients to return to their daily activities almost immediately. Our goal is to provide a seamless experience for those who come to us for help with their vascular health,”</p>
        <footer>— Dr. Bhaskar MV</footer>
    </blockquote>

    <h5>Why CLaCS is a Game-Changer</h5>
    <ul>
        <li><strong>Minimally Invasive:</strong> Requires no major incisions, ensuring quick recovery and minimal scarring.</li>
        <li><strong>Comfortable:</strong> Cooling technology reduces discomfort during the procedure.</li>
        <li><strong>Quick Recovery:</strong> Most patients can return to daily activities almost immediately.</li>
        <li><strong>Effective Results:</strong> Combines proven methods for reliable and lasting outcomes.</li>
    </ul>
    <div style={{display:"flex",justifyContent:"center"}} className="mb-3 flex justify-center">
        <LazyLoad>
            <img style={{maxWidth:"800px"}} src="../photo/ss12.jpg" alt="" />
        </LazyLoad>
    </div>
    <h5>Who Can Benefit?</h5>
    <p>Varicose veins and spider veins are common conditions affecting a large segment of the population, especially individuals who:</p>
    <ul>
        <li>Have a family history of vein conditions.</li>
        <li>Lead sedentary lifestyles or work jobs requiring prolonged sitting or standing.</li>
        <li>Seek effective solutions with minimal downtime.</li>
    </ul>
    <p>These conditions are not merely cosmetic but can lead to swelling, pain, and in severe cases, ulcers or blood clots. With CLaCS, patients now have an advanced, reliable solution to address these problems with minimal disruption to their lives.</p>

    <h5>Impact of CLaCS at Adhventha Hospital</h5>
    <p>The establishment of Karnataka’s first CLaCS center at Adhventha Hospital places Bangalore firmly on the map of innovative healthcare delivery in India. It reflects the city’s commitment to adopting global standards in medical care while fostering trust and reliability for patients. This development also positions Adhventha Hospital as a beacon of excellence in vascular health.</p>

    
    <h5>About Dr. Bhaskar MV</h5>
    <p><strong>Dr. Bhaskar MV</strong>, the Founder and Managing Director of Adhventha Hospital, is a <strong>Senior Consultant Endovascular Surgeon</strong> with impressive credentials:</p>
    <ul>
        <li><strong>MBBS, MD (Gold Medal), DNB, DM (NIMHANS)</strong></li>
        <li><strong>FRCR (London), NMANS (Singapore), EBIR, EDIR</strong></li>
        <li>Specialist in Interventional Neuro-Radiology</li>
    </ul>

    <h5>Conclusion</h5>
    <p>With <strong>Dr. Bhaskar MV</strong> at the helm, patients seeking treatment for varicose veins in Bangalore now have access to a pioneering approach that combines science, technology, and patient-centric care. The arrival of CLaCS is a testament to the ongoing advancements in healthcare and the dedication of professionals like Dr. Bhaskar to improving lives.</p>
    </div>
    </>)
}
export default memo(OneNineSub)