import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function TwoSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b13.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>After a day’s work, a tired body needs rest and sleep. It is a natural body mechanism that rejuvenates the body and mind and prepare for work again. But some people suffer from sleep disorder. Despite trying hard, they cannot sleep naturally. As a consequence, they feel tired after waking up from a night sleep and carry on the same tiredness all throughout the day. Lack of proper sleep thus gives rise to some serious health issues like depression, overweight or obesity, type 2 diabetes, high blood pressure, heart disease, kidney disease and memory and concentration problems.</p>
        <p>Before consulting a doctor for undergoing treatment for better sleep, you may try out 7 natural ways to have sufficient sleep at night.</p>
        <h6>Reduce Caffeine Intake –</h6>
        <p>A recent study indicates that consuming 400 mg caffeine six hours before bed would play a spoilsport to your sleep. So, reduce your intake of caffeine to enjoy a better sleep.</p>
        <h6>Train the Mind to Sleep Naturally – </h6>
        <p>Usually people fall asleep after 10-20 minutes of lights off. If you do not fall asleep even after 30 minutes of lying down, You may read a book or listen to some soothing music to relax your mind and simultaneously train the mind to sleep naturally.</p>
        <h6>Select your Bedroom in Quiet Place –</h6>
        <p>To have a better sleep, choose your bedroom in a quiet place away from any outside disturbances. You may use heavy curtains to induce a peaceful feeling.</p>
        <h6>Shut down all the Gadgets –</h6>
        <p>the blue light emits from the laptop, smart-phone, and tablet lowers the levels of melatonin, a hormone that makes people feel sleepy. So, while going to bed either shut down your gadgets or keep them away from your bed to enjoy a deep sleep.</p>
        <h6>Do Some Exercise regularly –</h6>
        <p>Regular exercise and a proper schedule for bed help many people combat sleep disorder and insomnia.</p>
        <p>Despite these attempts to invigorate sleep habit, if you suffer from sleep disorder it is better to consult a doctor. Usually people with sleep disorder suffer from sleep apnoea, Gastroesophageal reflux disease (GERD), Snoring, Restless Legs Syndrome, Narcolepsy, Parasomnias, Chronic Fatigue Syndrome and Seasonal Affective Disorder.</p>
        <p>Snoring though is not considered a medical disorder, in most cases it leads to sleep apnoea. In sleep apnoea a person experiences intermittent cessation of breath during sleep. As a result this leads to reduction in oxygen level in the blood, causing a person’s body to be jerked awake. Treatment for better sleep includes administering sleeping pills, cognitive behavioral therapy for insomnia (CBTI).</p>
        <p>Parasomnias can be treated with medicines such as melatonin while Chronic Fatigue Syndrome is treated by making lifestyle changes, sticking to a sleep routine, and practicing methods to conserve the patient’s energy.</p>
    </div>
    </>)
}
export default memo(TwoSub)