import { memo, useState } from "react"
import Newinser from "../Newserfolder/Newinser"
import LinkAddrees from "../Servicebody/LikeAddrees"
import Newcontactin from "../NewSections/Newcontactin"
import Abouthospital from "../About/AboutHospital"
import Aboutdoctor from "../About/Aboutdoctor"
import Ourdoctorlist from "./Ourdoctorlist"
import Newdoctorlist from "./Newdoctorlist"

function Ourinfosite(){
    const [names, setNames]=useState("")
    return (<>
        <section>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12 mb-0">
                        <Newdoctorlist setNames={setNames}/>
                    </div>
                    <div className="col-12">
                        <Ourdoctorlist names={names}/>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default memo(Ourinfosite)