import { memo } from "react"
import Headertwo from "../NewSections/Headertwo"
import Ourdoctorbanner from "./Ourdoctorbanner"
import Ourinfosite from "./Ourinfosite"
import Newfooter from "../NewSections/Newfooter"
import Newcontactin from "../NewSections/Newcontactin"
import Headernewtop from "../NewSections/Headernewtop"
import ContactUsinfo from "../CintactUsinfo"
import Mobcontroller from "../ContactButton/Mobcontroller"
import Contactbutton from "../ContactButton/Contactbutton"
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo"
import Newservicebanner from "../Newservicesbox/Newservicebanner"
import Contactinforlink from "../Newservicesbox/Contactinforlink"
import Functionmain from "../Functionmain"
import { useEffect } from "react"
import bannercontent from "../Newservicesbox/Bannercontent"

function Ourdoctor(){
    useEffect(()=>{
        Functionmain()
    },[])
    return (<>
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
        <Headernewtop/>
        {
        bannercontent.map((elem)=>{
            const {page, one, two}=elem
            if("doctor"==page)
            {
                return (<>
                    <Newservicebanner one={one} two={two}/>
                    </>)
            }
        })
    }
    <Ourinfosite/>
    <div className="md-3 mt-3">
    {/* <Newcontactin/> */}
    </div>
    <Contactinforlink/>
    {/* <ContactUsinfo/> */}
    <Newfooter/>
    </>)
}
export default memo(Ourdoctor)