import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Vascularsurgery(){
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Vascular Surgery</h2>
                    <p>Vascular surgery is a surgical specialty that focuses on the diagnosis, treatment, and management of conditions that affect the blood vessels, including arteries, veins, and lymphatic vessels. Vascular surgeons are medical doctors who specialize in vascular surgery and are trained to address a wide range of vascular disorders.</p>
                    <p>The circulatory system, which includes the blood vessels, plays a crucial role in transporting blood and nutrients throughout the body.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Vascular surgeons are skilled in both open surgical procedures and minimally invasive techniques to treat vascular conditions, which can include.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/uu1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Peripheral Artery Disease (PAD)</h6>
                            <p>Vascular surgeons diagnose and manage PAD, which is a condition characterized by narrowed or blocked arteries in the extremities, most commonly the legs.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/uu2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Aneurysms</h6>
                            <p>Vascular surgeons treat aneurysms, which are weakened and bulging areas in the blood vessels.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/uu3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Carotid Artery Disease</h6>
                            <p>Vascular surgeons manage carotid artery disease, which involves the narrowing or blockage of the carotid arteries that supply blood to the brain. </p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/uu4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Deep Vein Thrombosis (DVT)</h6>
                            <p>Vascular surgeons diagnose and treat DVT, which is the formation of blood clots in deep veins, most commonly in the legs.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/uu5.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Varicose Veins</h6>
                            <p>Vascular surgeons manage varicose veins, which are enlarged and twisted veins that often occur in the legs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12 p-3">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Vascularsurgery)