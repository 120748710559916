import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function Seven(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b7.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Back pain is one of the most common ailments troubling hundreds and thousands of people around the globe. It can affect people of any age but with ages the problem gets severe. Often it turns out so acute that it jeopardises the normal activities and movements. It can be caused by strain, tension, or injury. Lower back pain is usually linked to the bony lumbar spine, discs between the vertebrae, ligaments around the spine and discs, spinal cord and nerves, lower back muscles, abdominal and pelvic internal organs, and the skin around the lumbar area. While upper back pain is caused by the disorders of the aorta, tumours in the chest, and spine inflammation.</p>
        <p>Before starting back pain treatment the doctors resort to X-Ray, MRI and CT scan to know the exact region and source of pain.

X-Ray image shows alignment of the bones and detect signs of arthritis or broken bones. But this image does not reveal damage in the muscles, spinal cord, nerves, or discs.

MRI and CT scan image reveals herniated disks or problems with tissue, tendons, nerves, ligaments, blood vessels, muscles, and bones.

Whatever may be the cause doctors should be consulted to regain the normal activities and movement minimising the pain. Back pain treatment is offered by an Orthopaedist, Rheumatologist, Neurologist, Neurosurgeon and Physiotherapist.</p>
<h6>Orthopaedist:</h6>
<p>An orthopaedist treats ailments that affect the musculoskeletal system. Back pain caused by ruptured discs and scoliosis are usually addressed by the Orthopaedist. If necessary, he can perform surgeries also.

</p>
<h6>Rheumatologist:</h6>
<p>Back pain due to osteoarthritis is treated by a Rheumatologist.</p>
<h6>Neurologist:</h6>
<p>A neurologist deals with the ailments that affect human nervous system. For chronic back pain, the neurologist is the best person to consult since he is an expert in finding out the source of the pain.</p>
<h6>Neurosurgeons:</h6>
<p>If the back pain treatment procedures require surgery, it can be done by a neurosurgeon. He can also offer non- surgical back pain management treatment.</p>

    </div>
    </>)
}
export default memo(Seven)