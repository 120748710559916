import About from "./About/About";
import "./inner.css"
import { Link } from "react-router-dom";
function HeaderTop(){
    return <>
    <div className="headertop">
        <Link to="/" className="firstheaderbox">
            {/* <img className="headername" src="../photo/sec-logo.jpg" alt="" /> */}
            <img src="../photo/logo.jpg" alt="" />
        </Link>
        <div className="secheadertop">
            <div className="headertwo-one">
                <div className="header-icon">
                    <img src="../photo/phone-call (1).png" alt="" />
                </div>
                <p>adhventhahospital@gmail.com
                </p>
            </div>
            <div className="headertwo-one">
                <div className="header-icon">
                    <img src="../photo/black-back-closed-envelope-shape.png" alt="" />
                </div>
                <p>adhventhahospital@gmail.com
                </p>
            </div>
            <div>
                <Link to="/contact" className="contactbutton">Contact Us</Link>
            </div>
        </div>
    </div>
    </>
}
export default HeaderTop;