import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Seventenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a17.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Spine Surgery</h5>
        <p>Spine surgery is a surgical specialty that focuses on the diagnosis, treatment, and management of conditions affecting the spine. It involves surgical procedures performed on the spine to alleviate pain, correct deformities, stabilize the spine, and restore function.</p>
        <p>Spine surgeons are medical doctors who specialize in the surgical treatment of spine disorders. They undergo extensive training in orthopedic surgery or neurosurgery and further specialize in spine surgery through additional fellowship training.</p>
        <h6>Here are some common conditions that may require spine surgery:</h6>
        <h6>Herniated Disc:</h6>
        <p>When a disc in the spine ruptures or slips out of place, it can cause pressure on nearby nerves, resulting in pain, numbness, or weakness. Spine surgery, such as a discectomy or microdiscectomy, may be performed to remove the herniated portion of the disc and relieve nerve compression.</p>
        <h6>Spinal Stenosis:</h6>
        <p>Spinal stenosis is a narrowing of the spinal canal that can cause compression of the spinal cord or nerves. Surgical procedures like laminectomy or laminotomy may be performed to create more space in the spinal canal and alleviate pressure on the nerves.</p>
        <h6>Scoliosis:</h6>
        <p>Scoliosis is an abnormal curvature of the spine. Severe cases of scoliosis may require spinal fusion surgery, where the vertebrae are fused together to correct the curvature and stabilize the spine.</p>
        <p>Spine surgery techniques have advanced significantly in recent years, with minimally invasive approaches becoming more common. Minimally invasive spine surgery uses smaller incisions and specialized instruments, resulting in less tissue damage, reduced postoperative pain, and faster recovery.</p>
        <p>Spine surgeons work closely with a multidisciplinary team, including physical therapists, pain specialists, and rehabilitation specialists, to provide comprehensive care to patients with spine conditions. They assess each patient's specific condition, develop personalized treatment plans, perform surgical procedures, and provide postoperative care to ensure optimal recovery.</p>
        <p>If you are experiencing spinal problems or have been diagnosed with a spine condition, it is essential to consult with a qualified spine surgeon. They can evaluate your condition, discuss treatment options, and determine if spine surgery is appropriate for your specific case. The decision to undergo spine surgery is based on a thorough assessment of your symptoms, diagnostic imaging results, and consideration of non-surgical treatments that may be tried first.</p>
    </div>
    </>)
}
export default memo(Seventenser)