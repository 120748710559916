import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from 'react-lazy-load';

function Servicesbanner(){
    return (<>
    <LazyLoad className="banner servicesbanner">
        <div>
            <h3 className="mainbannertext"> Sevices </h3>
            <div className="bannertextf">
                <Link to="/" className="bannertextf"><h6> Home </h6></Link>
                <h6> / </h6>
                <h6> Services </h6>
            </div>
        </div>
    </LazyLoad>
    </>)
}
export default Servicesbanner;