import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function TwlveSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b12.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>According the stats provided by World Health Organization (WHO) almost 36 billion people from around the world lack medical services. Almost 100 million people get affected and end up in poverty paying for healthcare services. However, healthcare services in India have been bit better owing to the modern facilities and infrastructure provided by the hospitals. The multispecialty hospitals, mainly in Bangalore are providing all purpose medical facilities, to cater to the overall health of an individual.</p>
        <h5>Multispecialty Hospitals in Bengaluru– Changing the lives of people</h5>
        <p>The healthcare industry is ever-evolving with the changing needs of people. The systems are now laced with digital technologies and Artificial Intelligence and Machine Learning. Robotics and advanced laser surgery has improved the entire scenario of healthcare, paving a way for immense research in the field. It has enabled hospitals to optimize their patient care and management processes.</p>
        <p>However, the most significant change has occurred through the inception of ‘Multispecialty Hospitals.’ The traditional hospitals ideally functioned on just single specialty and some related subcategories within it. These hospitals have now evolved as ‘super specialty’ or ‘multi-specialty’ hospitals former focusing on one particular branch of medicine and the latter focusing on several or all aspects of the medical fraternity. The inception of multispecialty hospitals has saved a lot on the pockets of the patients as a single unit provides all medical facilities.</p>
        <h5>Benefits of multispecialty hospitals</h5>
        <h5>Multiple specialty teams</h5>
        <p>There are a number of problems patients face when they get admitted to traditional hospitals and are referred to different other diagnostic centers for different issues. A multispecialty hospital encompasses all the departments in medicine and help in complete check-up and treatment followed by consultations for the patients. The patient finds it easy to get his or her treatment done while visiting multispecialty hospital.

Most of all the problems are tackled within this medical infrastructure and the patient saves on his/her time and money by visiting a multispecialty hospital.</p>
<h5>2. Various kinds of equipments available under the same roof:</h5>
<p>Patients who need to get multiple tests done for their medical condition usually need to visit different laboratories and diagnostic centers. In case of a multispecialty hospital, it is really easy as the patient has all the facilities taken care of within the premises of a single edifice.</p>
<h5>3. Treatment of different lifestyle ailments simultaneously:</h5>
<p>Massive medical crises occurred in the 21st century because of the sedentary lifestyle. This caused a lot of lifestyle diseases of the bones, muscles, heart, and other organs in our body. The multispecialty hospitals came as a bonus when all the treatments were found available under the same roof. Advanced and emergency treatment methods were also available that helped people recover fast and easy.</p>
<h5>4. Finer lining of integration with insurance companies:</h5>
<p>A single traditional hospital may have just one or two insurance tie ups. However, the multispecialty hospitals make business with numerous insurance companies. Hence, when you visit a multispecialty hospital, you know you won’t be denied medical attention just because your insurance provider is not empanelled with the hospital.</p>
    </div>
    </>)
}
export default memo(TwlveSub)