import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import Carousel from 'react-bootstrap/Carousel';
import { useState } from "react";

function Slider() {
  return (<>
    <Carousel>
    <Carousel.Item>
        <div className="big">
        <img
          className="d-block w-100"
          src="../photo/aaa1.jpg"
          alt="First slide"
        />
        </div>
        <div className="small">
        <img
          className="d-block w-100"
          src="../photo/bbbb1.jpg"
          alt="First slide"
        />
        </div>
      </Carousel.Item>
    <Carousel.Item>
        <div className="big">
        <img
          className="d-block w-100"
          src="../photo/aaa6.jpg"
          alt="First slide"
        />
        </div>
        <div className="small">
        <img
          className="d-block w-100"
          src="../photo/bbbb6.jpg"
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="big">
        <img
          className="d-block w-100"
          src="../photo/mmmb2.jpg"
          alt="Second slide"
        />
        </div>
        <div className="small">
        <img
          className="d-block w-100"
          src="../photo/mmms2.jpg"
          alt="Second slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="big">
        <img
          className="d-block w-100"
          src="../photo/prbannerb.jpg"
          alt="First slide"
        />
        </div>
        <div className="small">
        <img
          className="d-block w-100"
          src="../photo/prbanners.jpg"
          alt="First slide"
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="big">
        <img
          className="d-block w-100"
          src="../photo/bbb4.jpg"
          alt="Second slide"
        />
        </div>
        <div className="small">
        <img
          className="d-block w-100"
          src="../photo/sss4.jpg"
          alt="Second slide"
        />
        </div>
      </Carousel.Item>
    </Carousel>
  </>
  );
}

export default Slider;