import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
import LazyLoad from 'react-lazy-load';
function Serviceinfo(props){
    return (<>
            <div className="section serback">
            <div className="container">
                <div className="tittle">
                    <h4>SERVICES</h4>
                    <h2>CENTRE OF EXPERTISE<br/>
ADHVENTHA INSTITUTE OF ADVANCED SURGERY</h2>
                </div> 
                <div className="row">
                    <div className="col-xl-4 col-md-6 serviceouter">
                        <div className="servicebox">
                            <LazyLoad><img src="../photo/oneone.jpg" alt="" /></LazyLoad>
                            <h5 className=" buttop1">Advanced Minimally Access Surgeries</h5>
                            <p>Advanced minimally access surgeries refer to surgical techniques that are performed through small incisions rather than large ones, which are typically required for traditional open surgeries. These types of surgeries use specialized instruments and a camera to guide the surgeon, allowing for greater precision and less damage.</p>
                            <div className="linkabouth">
                                <Link to="/Hospitalservice" onClick={()=>{
                                    props.setSerselect("Advanced Minimally Access Surgeries")
                                }} className="linkabouths"> Read More </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 serviceouter">
                        <div className="servicebox">
                            <LazyLoad><img src="../photo/twotwo.jpg" alt="" /></LazyLoad>
                            <h5>Non Invasive Pin Hole Endovascular Surgeries</h5>
                            <p>Non-invasive pinhole endovascular surgeries (also known as endovascular procedures) are minimally invasive procedures that involve the use of a thin, flexible tube (catheter) inserted through a small incision in the skin. The catheter is guided through the blood vessels to reach the area that requires treatment.</p>
                            <div className="linkabouth">
                                <Link to="/Hospitalservice" onClick={()=>{
                                    props.setSerselect("Non Invasive Pinhole Endovascular Surgeries")
                                }} className="linkabouths"> Read More </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 serviceouter">
                        <div className="servicebox">
                            <LazyLoad><img src="../photo/threeone.jpg" alt="" /></LazyLoad>
                            <h5 className=" buttop2">Plastic & Cosmetic Surgeries</h5>
                            <p>Plastic surgery is a surgical specialty that focuses on repairing or reconstructing defects or abnormalities in the body. It can be performed for medical or cosmetic purposes. Some examples of plastic surgery procedures include breast reconstruction after mastectomy, cleft lip and palate repair, and scar revision.</p>
                            <div className="linkabouth">
                                <Link to="/Hospitalservice" onClick={()=>{
                                    props.setSerselect("Plastic & Cosmetic Surgeries")
                                }} className="linkabouths"> Read More </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Serviceinfo;