import { memo } from "react"
import Gallerybanner from "./Gallerybanner"
import Headertwo from "../NewSections/Headertwo"
import Newfooter from "../NewSections/Newfooter"
import NewLinkbox from "../NewSections/NewLinkbox"
import Newcontactin from "../NewSections/Newcontactin"
import Gallerybox from "./Gallerybox"
import Headernewtop from "../NewSections/Headernewtop"
import ContactUsinfo from "../CintactUsinfo"
import Mobcontroller from "../ContactButton/Mobcontroller"
import Contactbutton from "../ContactButton/Contactbutton"
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo"
import Newservicebanner from "../Newservicesbox/Newservicebanner"
import Contactinforlink from "../Newservicesbox/Contactinforlink"
import { useEffect } from "react"
import Functionmain from "../Functionmain"
import bannercontent from "../Newservicesbox/Bannercontent"

function Gallery(){
    useEffect(()=>{
        Functionmain()
    },[])
    return (<>
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
        <Headernewtop/>
        
        {
        bannercontent.map((elem)=>{
            const {page, one, two}=elem
            if("gallery"==page)
            {
                return (<>
                    <Newservicebanner one={one} two={two}/>
                    </>)
            }
        })
    }
    <Gallerybox/>
    <div className="mt-5">
    <Contactinforlink/>
    </div>
    {/* <ContactUsinfo/> */}
    <Newfooter/>
    </>)
}
export default memo(Gallery)