import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Oneser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a8.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Neurosurgery</h5>
        <p>Neurosurgery is a medical specialty that focuses on the surgical treatment of conditions affecting the nervous system, including the brain, spinal cord, and peripheral nerves. Neurosurgeons are highly trained medical doctors who specialize in performing surgical interventions to diagnose, treat, and manage a wide range of neurological disorders and injuries.</p>
        <h6>Neurosurgery covers a diverse range of conditions and procedures, including:</h6>
        <h6>Brain tumor surgery: </h6>
        <p>Neurosurgeons perform surgeries to remove brain tumors, both cancerous (malignant) and non-cancerous (benign). They work closely with neuro-oncologists and radiation oncologists to develop comprehensive treatment plans.</p>
        <h6>Spinal surgery:</h6>
        <p>Neurosurgeons perform surgeries to address spinal conditions, such as herniated discs, spinal stenosis, spinal cord compression, and spinal deformities. They may perform procedures such as laminectomy, discectomy, spinal fusion, or spinal instrumentation to relieve pressure on the spinal cord and nerves.</p>
        <h6>Vascular neurosurgery:</h6>
        <p>Neurosurgeons specialize in treating conditions affecting the blood vessels of the brain and spinal cord, such as aneurysms, arteriovenous malformations (AVMs), and carotid artery disease. They may perform procedures such as clipping, coiling, or vascular bypass surgery to restore normal blood flow and prevent complications.</p>
        <h6>Functional neurosurgery:</h6>
        <p>Neurosurgeons may perform surgeries to treat movement disorders like Parkinson's disease, essential tremor, or dystonia. Deep brain stimulation (DBS) is a common procedure used to implant electrodes and deliver electrical impulses to specific areas of the brain to alleviate symptoms.</p>
        <p>Neurosurgeons work closely with other healthcare professionals, including neurologists, neuroradiologists, anesthesiologists, and critical care teams. They utilize advanced imaging techniques, such as MRI and CT scans, to accurately diagnose and plan surgical interventions. In addition to surgery, neurosurgeons may also provide non-surgical treatments and interventions, including medications, radiation therapy, and minimally invasive procedures.</p>
        <p>It's important to note that neurosurgery is a complex and specialized field, and treatment decisions are made on an individual basis. If you have specific concerns or require neurosurgical care, it is recommended to consult with a qualified neurosurgeon or healthcare professional for an accurate diagnosis and personalized treatment plan.</p>
    </div>
    </>)
}
export default memo(Oneser)