import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Sevenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a7.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Nephrology</h5>
        <p>Nephrology is a medical specialty that focuses on the diagnosis, treatment, and management of conditions related to the kidneys and urinary system. Nephrologists are medical doctors who specialize in nephrology and are trained to provide care for patients with kidney diseases and disorders.</p>
        <p>The kidneys play a crucial role in maintaining the body's fluid balance, filtering waste products from the blood, regulating electrolyte levels, and producing hormones that control blood pressure and red blood cell production. Nephrologists are responsible for evaluating and managing a wide range of kidney-related conditions, including:</p>
        <h6>Chronic kidney disease (CKD): </h6>
        <p>Nephrologists are involved in the diagnosis, staging, and management of CKD, which is a progressive loss of kidney function over time. They work to slow the progression of the disease, manage complications, and coordinate renal replacement therapies such as dialysis or kidney transplantation.</p>
        <h6>Acute kidney injury (AKI): </h6>
        <p>Nephrologists evaluate and manage sudden and temporary loss of kidney function, which can be caused by various factors such as severe infections, dehydration, medications, or trauma. They aim to identify the underlying cause and provide appropriate treatment to restore kidney function.</p>
        <h6>Kidney stones:</h6>
        <p>Nephrologists diagnose and manage kidney stones, which are hard mineral and salt deposits that form in the kidneys. They may recommend lifestyle modifications, dietary changes, medications, or surgical interventions to treat and prevent the recurrence of kidney stones.</p>
        <h6>Hypertension (high blood pressure): </h6>
        <p>Nephrologists specialize in the management of hypertension, particularly when it is associated with kidney disease. They work to control blood pressure levels and prevent further damage to the kidneys.</p>
        <h6>Glomerular diseases:</h6>
        <p>Nephrologists evaluate and manage conditions that affect the glomeruli, which are the filtering units of the kidneys. These conditions include glomerulonephritis, nephrotic syndrome, and autoimmune-related kidney diseases.</p>
        <p>Nephrologists use a combination of diagnostic techniques and tools to assess kidney function and identify underlying conditions. These may include blood and urine tests, imaging studies (such as ultrasounds or CT scans), kidney biopsies, and other specialized tests to evaluate kidney function and determine the most appropriate treatment plan.</p>
        <p>Nephrologists work closely with other healthcare professionals, including primary care physicians, urologists, surgeons, and dietitians, to provide comprehensive care to patients with kidney conditions. They develop individualized treatment plans, prescribe medications, manage complications, provide dietary recommendations, and offer patient education to optimize kidney health.</p>
        <p>If you have concerns related to kidney health, kidney disease, or urinary system disorders, it is recommended to consult with a qualified nephrologist or healthcare professional for an accurate diagnosis and appropriate management plan tailored to your specific needs.</p>
    </div>
    </>)
}
export default memo(Sevenser)