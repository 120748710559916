import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function TwoSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b2.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <h5>What exactly is a psychiatric disorder?</h5>
        <p>Psychological illnesses, often known as mental disorders, are mental imbalances that lead to persistent specific behaviors that can significantly interfere with your day-to-day function and existence.</p>
        <h5>What are the most common mental illnesses?</h5>
        <p>1) Panic disorder, obsessive-compulsive disorder, and phobias are examples of anxiety disorders.</p>
        <p>2) Mood disorders include depression, bipolar disease, and other mood disorders.</p>
        <p>3) Eating problems are common.</p>
        <p>4) Personality flaws.</p>
        <p>5) Post-traumatic stress disorder (PTSD).</p>
        <h5>Here are the most typical causes of psychiatric problems?</h5>
        <p>1) Your ancestors family history</p>
        <p>2) Your experiences growing up, like stress or a traumatic experience, particularly if they occurred as a child.</p>
        <p>3) Chemical imbalances in the brain are examples of biological variables.</p>
        <p>4) TBI stands for traumatic brain damage.</p>
        <h5>Is it possible to treat psychological disorders?</h5>
        <p>There is no cure for mental disease. While certain illnesses are considered permanent – even with therapy – many others may be properly treated and conquered.</p>
        <h5>What is the importance of treating psychological disorders?</h5>
        <p>Living with a mental disorder is always difficult, but receiving good treatment will make it much more bearable, enabling you to appreciate everything life has to offer without your condition interfering. Seeking therapy can lead to additional advantages in addition to improved mental health.</p>
        <p>First and first, treating and managing your mental illness will assist to enhance your quality of life. allowing you to appreciate everything life has to offer without being restricted by your condition
Seeking therapy can lead to additional advantages in addition to improved mental health. Good mental health can improve physical health by enhancing sleeping patterns, the immune system, and reducing pain levels. Having your mental health handled may also increase your productivity by helping you to focus on everyday activities and providing you with the drive to finish tasks on time.</p>
<p>Improving your mental health might help you live longer.
Seeking therapy for mental health issues is essential, regardless of the type of treatment you choose. From medicine to counseling to one of our clinical studies, there is a solution for everyone, and we urge you to discover one so you may live a happier, healthier life.</p>
    </div>
    </>)
}
export default memo(TwoSub)