import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Fourser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a9.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Oncology</h5>
        <p>Oncology is a medical specialty that focuses on the prevention, diagnosis, and treatment of cancer. Oncologists are medical doctors who specialize in the field of oncology and are responsible for managing all aspects of cancer care, including determining the most appropriate treatment options, monitoring patients' progress, and providing supportive care.</p>
        <h6>Oncology encompasses several subspecialties, including:</h6>
        <h6>Medical Oncology:</h6>
        <p>Medical oncologists are primarily involved in the non-surgical treatment of cancer. They use systemic therapies such as chemotherapy, targeted therapy, immunotherapy, and hormonal therapy to treat cancer. Medical oncologists work closely with other specialists to develop individualized treatment plans for patients based on the type, stage, and characteristics of their cancer.</p>
        <h6>Surgical Oncology:</h6>
        <p>Surgical oncologists specialize in the surgical management of cancer. They perform biopsies to diagnose cancer, as well as remove tumors and surrounding tissue through surgical procedures. Surgical oncologists may also perform reconstructive surgery after cancer removal and work in collaboration with other oncologists to develop comprehensive treatment plans.</p>
        <h6>Radiation Oncology:</h6>
        <p>Radiation oncologists specialize in using radiation therapy to treat cancer. They determine the appropriate radiation treatment plans, deliver radiation therapy, and monitor patients throughout the course of treatment. Radiation therapy may be used as the primary treatment or in combination with surgery or chemotherapy.</p>
        <h6>Pediatric Oncology:</h6>
        <p>Pediatric oncologists specialize in diagnosing and treating cancer in children. They have expertise in managing the unique types of cancers that occur in children and providing age-appropriate care. Pediatric oncologists work closely with multidisciplinary teams to provide comprehensive care, considering the emotional and developmental needs of young patients and their families.</p>
        <h6>Hematologic Oncology:</h6>
        <p>Hematologic oncologists focus on cancers that affect the blood and blood-forming tissues, such as leukemia, lymphoma, and multiple myeloma. They diagnose and treat these cancers using chemotherapy, targeted therapy, immunotherapy, and stem cell transplantation.</p>
        <p>Oncologists work collaboratively with other healthcare professionals, including radiologists, pathologists, nurses, and palliative care specialists, to provide comprehensive care to cancer patients. They utilize various diagnostic tools and techniques, including imaging studies, laboratory tests, and molecular profiling, to accurately diagnose and stage cancer. Oncologists also play a critical role in providing supportive care to manage cancer-related symptoms and improve the quality of life for patients.</p>
        <p>In addition to treatment, oncologists are involved in cancer prevention, screening, and research. They educate patients about cancer risk factors, promote healthy lifestyle choices, and may participate in clinical trials to advance cancer treatment options.</p>
        <p>If you or a loved one is diagnosed with cancer or have concerns related to cancer, it is important to seek the expertise of a qualified oncologist who can provide an accurate diagnosis, discuss treatment options, and develop a personalized care plan based on your specific needs and circumstances.</p>
    </div>
    </>)
}
export default memo(Fourser)