import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Oneser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a6.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Physiotherapy</h5>
        <p>Physiotherapy, also known as physical therapy, is a healthcare profession that focuses on promoting, restoring, and maintaining physical function and mobility. Physiotherapists, or physical therapists, are trained healthcare professionals who use a variety of techniques and interventions to help individuals improve their physical well-being and quality of life.</p>
        <p>The goal of physiotherapy is to address a wide range of conditions, injuries, and disorders that affect the musculoskeletal, neurological, and cardiovascular systems. Physiotherapists work with individuals of all ages and across various healthcare settings, including hospitals, clinics, rehabilitation centers, and private practices.</p>
        <h6>Here are some common areas of focus within physiotherapy:</h6>
        <p>Rehabilitation after injury or surgery: Physiotherapists help individuals recover from injuries, such as sprains, strains, fractures, or post-operative rehabilitation. They develop customized treatment plans that may include exercises, manual therapy, pain management techniques, and modalities like heat or cold therapy.</p>
        <p>Pain management: Physiotherapists assess and manage pain associated with musculoskeletal conditions, chronic pain syndromes, or post-surgical pain. They may use techniques such as manual therapy, electrical stimulation, ultrasound, and therapeutic exercises to alleviate pain and improve function.</p>
        <p>Musculoskeletal conditions: Physiotherapists diagnose and treat a wide range of musculoskeletal conditions, including back and neck pain, osteoarthritis, tendonitis, and sports injuries. They provide targeted interventions such as manual therapy, therapeutic exercise, and patient education to optimize movement and function.</p>
        <p>Neurological rehabilitation: Physiotherapists work with individuals who have neurological conditions such as stroke, spinal cord injury, multiple sclerosis, or Parkinson's disease. They focus on improving mobility, balance, coordination, and strength through specialized exercises and techniques.</p>
        <p>Cardiopulmonary rehabilitation: Physiotherapists play a role in cardiac and pulmonary rehabilitation programs. They design exercise programs and provide education to individuals with heart conditions, lung diseases, or after cardiac surgery to improve cardiovascular fitness, breathing techniques, and overall functional capacity.</p>
        <h6>Physiotherapists utilize a range of techniques and modalities in their practice, including:</h6>
        <p>Therapeutic exercises: Customized exercises to improve strength, flexibility, endurance, and coordination.</p>
        <p>Manual therapy: Hands-on techniques such as joint mobilization, soft tissue mobilization, and manipulation to improve joint and tissue mobility.</p>
        <p>Physiotherapists also emphasize patient education, teaching individuals how to manage their conditions, prevent further injuries, and maintain physical function through home exercise programs and lifestyle modifications.</p>
        <p>It's important to note that physiotherapy interventions are highly individualized, and treatment plans are tailored to each person's specific needs, goals, and medical condition. If you have specific concerns or require physiotherapy services, it is recommended to consult with a qualified physiotherapist or healthcare professional for an accurate assessment and personalized care plan.</p>
    </div>
    </>)
}
export default memo(Oneser)