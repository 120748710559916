import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Fiftyser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a15.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Pulmonology</h5>
        <p>Pulmonology is a medical specialty that focuses on the diagnosis, treatment, and management of diseases and conditions that affect the respiratory system, specifically the lungs and the airways. Physicians who specialize in pulmonology are called pulmonologists or pulmonary medicine specialists.</p>
        <h6>Pulmonologists are trained in the evaluation and management of a wide range of respiratory conditions, including:</h6>
        <h6>Asthma:</h6>
        <p>Pulmonologists diagnose and manage asthma, a chronic condition characterized by inflammation and narrowing of the airways. They help develop personalized treatment plans that may involve medications, inhalers, and lifestyle modifications to control symptoms and prevent asthma attacks.</p>
        <h6>Chronic Obstructive Pulmonary Disease (COPD):</h6>
        <p>COPD is a progressive lung disease that includes chronic bronchitis and emphysema. Pulmonologists play a key role in the diagnosis, treatment, and ongoing management of COPD. They may prescribe medications, provide pulmonary rehabilitation, and offer strategies to improve breathing and quality of life.</p>
        <h6>Respiratory Infections:</h6>
        <p>Pulmonologists diagnose and treat various respiratory infections, including pneumonia, bronchitis, and tuberculosis. They determine the appropriate antibiotic or antiviral treatments and monitor patients' response to therapy.</p>
        <h6>Interstitial Lung Diseases: </h6>
        <p>Pulmonologists specialize in the diagnosis and management of interstitial lung diseases (ILD), a group of lung disorders characterized by inflammation and scarring of the lung tissue. They may perform lung function tests, imaging studies, and lung biopsies to evaluate the condition and develop treatment plans.</p>
        <h6>Sleep-Related Breathing Disorders:</h6>
        <p>Pulmonologists evaluate and treat sleep-related breathing disorders, such as obstructive sleep apnea. They may prescribe continuous positive airway pressure (CPAP) therapy, conduct sleep studies, and provide recommendations for improving sleep quality and reducing associated health risks.</p>
        <p>Pulmonologists work closely with other healthcare professionals, including respiratory therapists, radiologists, and critical care specialists, to provide comprehensive care for patients with respiratory conditions. They also play a role in critical care medicine, managing patients in intensive care units who require respiratory support.</p>
        <p>If you are experiencing respiratory symptoms or have concerns about your lung health, it is advisable to consult with a pulmonologist. They can evaluate your symptoms, order appropriate tests, provide an accurate diagnosis, and develop an individualized treatment plan to manage your respiratory condition effectively.</p>
    </div>
    </>)
}
export default memo(Fiftyser)