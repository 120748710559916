import { memo } from "react";
import LazyLoad from "react-lazy-load";

function Dermatology() {
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Dermatology</h2>
                    <p>Dermatology is a medical specialty that focuses on the diagnosis, treatment, and prevention of conditions related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide care for patients with a wide range of skin conditions. The skin is the largest organ of the body and serves as a protective barrier against the external environment.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Dermatologists are trained to diagnose and treat various skin disorders, including.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/bb2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Dermatitis and eczema</h6>
                            <p>These conditions involve inflammation of the skin, resulting in symptoms such as redness, itching, and rash. They can be caused by allergens, irritants, genetic factors, or immune system dysfunction.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/bb3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Acne</h6>
                            <p>Acne is a common skin condition that occurs when hair follicles become clogged with oil and dead skin cells. It can range from mild to severe and may cause pimples, blackheads, and whiteheads.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/bb4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Psoriasis</h6>
                            <p>Psoriasis is a chronic autoimmune disease that causes the rapid buildup of skin cells, resulting in thick, red, scaly patches. It can affect any part of the body and may be associated with joint pain in some cases.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/bb5.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Skin infections</h6>
                            <p>Dermatologists diagnose and treat various bacterial, viral, and fungal infections that affect the skin. Examples include impetigo, warts, cold sores, athlete's foot, and fungal nail infections.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/bb6.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Skin cancer</h6>
                            <p>Dermatologists play a crucial role in the early detection and treatment of skin cancer.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/bb7.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Hair and nail disorders</h6>
                            <p>Dermatologists also evaluate and manage conditions affecting the hair and nails. This includes hair loss (alopecia), scalp infections, dandruff, nail infections, and nail abnormalities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Dermatology)