import Eightser from "./Subservices/Eightser";
import Eighttenser from "./Subservices/Eighttenser";
import Fiftyenser from "./Subservices/Fiftyenser";
import Fiveser from "./Subservices/Fiveser";
import Fortyser from "./Subservices/Fortyser";
import Fourser from "./Subservices/Fourser";
import Levensex from "./Subservices/Levensex";
import Nineser from "./Subservices/Nineser";
import Ninteenser from "./Subservices/Ninteenser";
import Oneser from "./Subservices/Oneser";
import Sevenser from "./Subservices/Sevenser";
import Seventenser from "./Subservices/Seventenser";
import Sixser from "./Subservices/Sixser";
import Sixtenser from "./Subservices/Sixtenser";
import Tenser from "./Subservices/Tenser";
import Threeser from "./Subservices/Threeser";
import Thretyser from "./Subservices/Thretyser";
import Twlvser from "./Subservices/Twlvser";
import Twoser from "./Subservices/Twoser";

const serv=[
    {
        nmae:"Cardiology",
        address:<Oneser/>,
    },
    {
        nmae:"Dermatology",
        address:<Twoser/>
    },
    {
        nmae:"General Medicine",
        address:<Threeser/>
    },
    {
        nmae:"Anesthesiology",
        address:<Fourser/>
    },
    {
        nmae:"General Surgeon",
        address:<Fiveser/>
    },
    {
        nmae:"Physiotherapy",
        address:<Sixser/>
    },
    {
        nmae:"Nephrology",
        address:<Sevenser/>
    },
    {
        nmae:"Neurosurgery",
        address:<Eightser/>
    },
    {
        nmae:"Oncology",
        address:<Nineser/>
    },
    {
        nmae:"Orthopaedics",
        address:<Tenser/>
    },
    {
        nmae:"Paediatrics",
        address:<Levensex/>
    },
    {
        nmae:"Pathology",
        address:<Twlvser/>
    },
    {
        nmae:"Plastic surgery",
        address:<Thretyser/>
    },
    {
        nmae:"Psychiatry",
        address:<Fortyser/>
    },
    {
        nmae:"Pulmonology",
        address:<Fiftyenser/>
    },
    {
        nmae:"Radiology",
        address:<Sixtenser/>
    },
    {
        nmae:"Spine Surgery",
        address:<Seventenser/>
    },
    {
        nmae:"Urology",
        address:<Eighttenser/>
    },
    {
        nmae:"Vascular Surgery",
        address:<Ninteenser/>
    },
]
export default serv;