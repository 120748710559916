
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"

function Vissionandmission() {
    return (
        <>
            <div className="section pb-0">
                
        <div className="container center-text mb-3">
            <h2>Our Core Values</h2>
        </div>
                <div className="container">
                    {/* <div className="row">
                        <div className="col-lg-6 missioncon">
                            <div className="missiobban"></div>
                            <div className="mission">
                                <h4>Our Mission</h4>
                                <h6>Provide comprehensive medical care that is patient-centered, innovative, and evidence-based, with a focus on preventive medicine and early detection of illnesses by continuously improving clinical practices, adopting new technologies, and adhering to the highest standards of quality and safety. </h6>
                            </div>
                        </div>
                        <div className="col-lg-6 missioncon">
                            <div className="vissiobban"></div>
                            <div className="vissionhigh">
                                <div className="mission">
                                    <h4>Our Vision</h4>
                                    <h6>As a premier healthcare institution provide accessible, affordable, and exceptional medical services with high-tech technology and quality. </h6>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div onDr className="row">
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Patient-Centered Care</h5>
                                <p>We believe that our patients are at the heart of everything we do, and we strive to provide care that is compassionate, respectful, and tailored to their unique needs and preferences.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Clinical Excellence</h5>
                                <p>We are committed to providing the highest quality of care by ensuring that our staff are well-trained, knowledgeable, and competent and by continuously improving our clinical practices and processes.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Innovation</h5>
                                <p>We embrace innovation and technology to improve patient care, enhance efficiency, and reduce costs while maintaining a focus on patient safety and quality.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Collaboration</h5>
                                <p>We promote a culture of teamwork and collaboration among our staff and with external stakeholders, such as patients, families, and community organizations, to achieve our shared goals.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Ethics and Integrity</h5>
                                <p>We conduct our business with the highest ethical and professional standards and are committed to transparency, accountability, and responsible stewardship of resources.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Continuous Learning and Improvement</h5>
                                <p>We believe in continuous learning and improvement and provide opportunities for our staff to enhance their skills and expertise through ongoing education and professional development initiatives.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Access and Affordability</h5>
                                <p>We are dedicated to providing accessible and affordable healthcare services to all, regardless of their socio-economic background or ability to pay, and strive to remove barriers to care by offering convenient locations, flexible scheduling, and a welcoming environment.</p>
                                </div>
                            </div><div className="col-xl-3 col-md-4 col-sm-6 p-1">
                                <div className="bordervaluea">
                                <h5>Preventive Care</h5>
                                <p>We believe in the importance of preventive care and early detection of illnesses, and work to promote healthy lifestyles, educate our patients on disease prevention and management, and provide screening and diagnostic services.</p>
                                </div>
                            </div>
                            </div>
                </div>
            </div>
        </>
        )
}
export default Vissionandmission;