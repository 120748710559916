import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";

function Mobilecontdroller(props) {
  function fromchanger() {
    props.setFormcontrol("block");
  }
  return (
    <>
      <div className="mobilecontrol">
        <div className="row">
          <div className="col-12 mobicon">
            <p>
              Please contact us with your question and needs, our
              <br className="fhu"></br> team would be delighted to assist you
              promptly.
            </p>
          </div>
          <div className="col-12 buttonmob">
            <div className="display-flex iconcenter">
              <a
                className="homecall textimg paddimg firsticon"
                href="https://www.facebook.com/adhventhahospital"
              >
                <img src="../../photo/facebook.png" alt="" />
              </a>
              <a
                className="homecall textimg paddimg firsticon"
                href="https://www.instagram.com/adhventhahospital/"
              >
                <img src="../../photo/instagram.png" alt="" />
              </a>
              <a
                className="homecall textimg paddimg firsticon"
                href="https://www.google.com/maps/place/%E0%B2%95%E0%B2%BE%E0%B2%9F%E0%B2%A8%E0%B3%8D+%E0%B2%95%E0%B3%8B%E0%B2%9A%E0%B3%8D/@12.9175633,77.5776089,17z/data=!4m14!1m7!3m6!1s0x3bae15858e534d51:0x1f5960042a254008!2sAdhventha+Hospital!8m2!3d12.9175633!4d77.5776089!16s%2Fg%2F11khnn6jsd!3m5!1s0x3bae15893fed7469:0xc009a9ce7b6bddee!8m2!3d12.914283!4d77.5789802!16s%2Fg%2F11h9hcs2d3?entry=ttu"
              >
                <img src="../../photo/google-maps.png" alt="" />
              </a>
              <button
                onClick={fromchanger}
                style={{ border: "0px" }}
                className="homecall textimg paddimg firsticon backchan"
                href=""
              >
                <img src="../../photo/contacta.png" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="sooboxone">
          <div className="sooin">
            <a href="https://wa.me/9342595404?text=Hello, How can I help you??">
              <div className="soonon">
                <LazyLoadImage src="../../photo/whatsapp.png"></LazyLoadImage>
              </div>
            </a>
          </div>
          <div className="soointo">
            <a href="tel:9342595404">
              <div className="soonon">
                <LazyLoadImage src="../../photo/telephone.png"></LazyLoadImage>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(Mobilecontdroller);
