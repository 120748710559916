import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Sixtenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a16.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Radiology</h5>
        <p>Radiology is a medical specialty that focuses on the use of medical imaging techniques to diagnose and treat various diseases and conditions. Radiologists are physicians who specialize in interpreting medical images and providing diagnostic reports to assist in patient care.</p>
        <h6>Radiology encompasses several imaging modalities, including:</h6>
        <h6>X-rays: </h6>
        <p>X-rays use a small amount of radiation to create images of the internal structures of the body. They are commonly used to evaluate bone fractures, lung conditions, and certain abdominal conditions.</p>
        <h6>Computed Tomography (CT):</h6>
        <p>CT scans use a combination of X-rays and computer technology to create detailed cross-sectional images of the body. They provide valuable information about various organs and structures, and are particularly useful for diagnosing conditions like tumors, injuries, and vascular diseases.</p>
        <h6>Magnetic Resonance Imaging (MRI):</h6>
        <p>MRI uses powerful magnets and radio waves to create detailed images of the body's soft tissues, such as the brain, spinal cord, joints, and organs. MRI is particularly useful for assessing neurological conditions, joint abnormalities, and tumors.</p>
        <h6>Nuclear Medicine:</h6>
        <p>Nuclear medicine involves the use of radioactive materials, called radiopharmaceuticals, to visualize organ function and assess diseases. Techniques such as positron emission tomography (PET) and single-photo/n emission computed tomography (SPECT) are used to detect metabolic and functional changes in the body.</p>
        <h6>Radiologists play a vital role in diagnosing and monitoring various conditions across medical specialties, including but not limited to:</h6>
        <p>Musculoskeletal disorders, such as fractures, arthritis, and tumors.
Cardiovascular conditions, such as coronary artery disease and aneurysms.
Respiratory diseases, such as lung cancer and pulmonary embolism.
Gastrointestinal conditions, including gastrointestinal bleeding and liver disease.
Neurological disorders, such as strokes, brain tumors, and multiple sclerosis.
Oncology, where radiologists assess tumors, monitor treatment response, and plan radiation therapy.</p>
<p>Radiologists collaborate closely with other healthcare professionals, including referring physicians, surgeons, and oncologists, to provide accurate and timely diagnoses. They analyze and interpret medical images, provide detailed reports, and assist in the selection and planning of appropriate treatments.</p>
<p>It's important to note that radiologists typically do not have direct patient contact but work behind the scenes to provide crucial diagnostic information. If you require medical imaging or have questions about your imaging results, your primary care physician or specialist will consult with a radiologist to interpret the images and guide your diagnosis and treatment plan.</p>
    </div>
    </>)
}
export default memo(Sixtenser)