import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Ninteenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a19.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Vascular Surgery</h5>
        <p>Vascular surgery is a surgical specialty that focuses on the diagnosis, treatment, and management of conditions that affect the blood vessels, including arteries, veins, and lymphatic vessels. Vascular surgeons are medical doctors who specialize in vascular surgery and are trained to address a wide range of vascular disorders.</p>
        <p>The circulatory system, which includes the blood vessels, plays a crucial role in transporting blood and nutrients throughout the body. Vascular surgeons are skilled in both open surgical procedures and minimally invasive techniques to treat vascular conditions, which can include:</p>
        <h6>Peripheral Artery Disease (PAD):</h6>
        <p>Vascular surgeons diagnose and manage PAD, which is a condition characterized by narrowed or blocked arteries in the extremities, most commonly the legs. They may recommend lifestyle modifications, medications, angioplasty, stenting, or bypass surgery to improve blood flow and alleviate symptoms.</p>
        <h6>Aneurysms:</h6>
        <p>Vascular surgeons treat aneurysms, which are weakened and bulging areas in the blood vessels. Depending on the location and size of the aneurysm, they may perform open repair surgery or endovascular aneurysm repair (EVAR) to prevent rupture and potentially life-threatening complications.</p>
        <h6>Carotid Artery Disease:</h6>
        <p>Vascular surgeons manage carotid artery disease, which involves the narrowing or blockage of the carotid arteries that supply blood to the brain. They may perform carotid endarterectomy or carotid artery stenting to restore blood flow and reduce the risk of stroke.</p>
        <h6>Deep Vein Thrombosis (DVT):</h6>
        <p>Vascular surgeons diagnose and treat DVT, which is the formation of blood clots in deep veins, most commonly in the legs. They may prescribe blood-thinning medications, recommend compression stockings, or perform procedures such as thrombolysis or thrombectomy to dissolve or remove the blood clot.</p>
        <h6>Varicose Veins:</h6>
        <p>Vascular surgeons manage varicose veins, which are enlarged and twisted veins that often occur in the legs. They may perform minimally invasive procedures such as endovenous ablation, sclerotherapy, or vein stripping to alleviate symptoms and improve the appearance of the veins.</p>
        <p>Vascular surgeons often work in multidisciplinary teams, collaborating with other specialists such as interventional radiologists, cardiologists, and vascular medicine physicians, to provide comprehensive care to patients with complex vascular conditions. They assess each patient's specific condition, determine the most appropriate treatment options, and perform surgical interventions when necessary.</p>
        <p>If you have symptoms or concerns related to your blood vessels, such as pain, swelling, or impaired circulation, it is advisable to consult with a vascular surgeon. They can evaluate your condition, order relevant tests and imaging studies, provide an accurate diagnosis, and recommend appropriate treatment options to address your specific vascular condition and improve your overall vascular health.</p>
    </div>
    </>)
}
export default memo(Ninteenser)