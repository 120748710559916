import { memo, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from "react-lazy-load"
import { data3 } from "../../App"

function Homeblog(){
    const changers=useContext(data3);
    const menu=[
        {
            img:"../photo/ss9.jpg",
            name:"Laser Treatment for Varicose Veins and Thyroid Ablation",
            content:"As medical technology advances, laser treatments are transforming how we manage various health conditions...",
        },
        {
            img:"../photo/ss7.jpg",
            name:"The Ultimate Guide to Varicose Vein Laser Treatment",
            content:"Varicose veins are a common concern that affects millions, causing both aesthetic discomfort and, in many cases, significant pain...",
        },
        {
            img:"../photo/ss6.jpg",
            name:"Revolutionary Laser Therapies",
            content:"Laser technology is changing the face of modern medicine, providing minimally invasive, precise treatments for conditions...",
        },
        {
            img:"../photo/ss5.jpg",
            name:"Endovenous Laser Treatment (EVLT) for Varicose Veins",
            content:"TVaricose veins affect millions of people worldwide, leading to discomfort, swelling, and visible, bulging veins that often affect confidence...",
        },
        {
            img:"../photo/ss9.jpg",
            name:"Laser Treatment for Varicose Veins and Thyroid Ablation",
            content:"As medical technology advances, laser treatments are transforming how we manage various health conditions...",
        },
        {
            img:"../photo/ss7.jpg",
            name:"The Ultimate Guide to Varicose Vein Laser Treatment",
            content:"Varicose veins are a common concern that affects millions, causing both aesthetic discomfort and, in many cases, significant pain...",
        },
    ]
    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    useEffect(()=>{
        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },2000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(25)
                }else if(window.innerWidth>992){
                    setNumber(33.3333)
                }else if(window.innerWidth>768){
                    setNumber(50)
                }else{
                    setNumber(100)
                }
                first();
                if(index>4){
                    second()
                }
            }
            iamgod()
        },3000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }
    return (<>
    <section className="mb5">
        <div className=" relpo">
            <div className=" container relpo blogbox">
            <div className="row rowofblogp">
                <div className="col-xl-3 col-lg-4 col-md-6 homeblog minerblog alinitemcenter widtherhome">
                    <div className="homebloga">
                    <h5>BLOG</h5>
                    <h1>Latest Articles & Upadtes</h1>
                    </div>
                    {/* <div className="headerthree mb-3 posireli">
                        <ul className="headicona">
                            <li><a href=""><span className="headicopada"><img src="../photo/facebook-app-symbol.png" alt="" /></span></a></li>
                            <li><a href=""><span className="headicopada"><img src="../photo/social (1).png" alt="" /></span></a></li>
                            <li><a href=""><span className="headicopada"><img src="../photo/instagram (6).png" alt="" /></span></a></li>
                            <li><a href=""><span className="headicopada"><img src="../photo/google-plus-logo.png" alt="" /></span></a></li>
                        </ul>
                    </div> */}
                </div>
            </div>
            </div>    
            <div className="container">
                <div className="rowhomehi">
                <div style={changer} className="rowhome">
                    {
                        menu.map((elem)=>{
                            const {img, name, content}=elem
                            {
                                return (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 blogarea">
                        <div className="newblog">
                            <img src={img} alt="" />
                        </div>
                        <h5>{name}</h5>
                        <p>{content}</p>
                        <Link to="/subblog" onClick={()=>{
                            changers(name)
                        }} className="linkinnew"><h6>READ MORE</h6></Link>
                    </div>
                                )
                            }
                        })
                    }
                    {
                        menu.map((elem)=>{
                            const {img, name, content}=elem
                            {
                                return (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 blogarea">
                        <LazyLoad className="newblog">
                            <img src={img} alt="" />
                        </LazyLoad>
                        <h5>{name}</h5>
                        <p>{content}</p>
                        <Link to="/subblog" className="linkinnew"><h6>READ MORE</h6></Link>
                    </div>
                                )
                            }
                        })
                    }
                </div>
                </div>
                
            </div>
        </div>
    </section>
    </>)
}
export default memo(Homeblog)