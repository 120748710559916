import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./inner.css"
function Mainservice(){
    return (<>
    <div className="mt-4">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 maininfo">
                    <div className="secmaininfoa">
                        <img className="image-with-shadow" src="../photo/ct-scan.png" alt="" />
                        <h6>Interventional Radiology</h6>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 maininfo">
                    <div className="secmaininfoa">
                        <img className="image-with-shadow" src="../photo/24-hours.png" alt="" />
                        <h6>Emergency Services</h6>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 maininfo">
                    <div className="secmaininfoa">
                        <img className="image-with-shadow" src="../photo/blood-test.png" alt="" />
                        <h6>Laboratory Services</h6>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 maininfo">
                    <div className="secmaininfoa">
                        <img className="image-with-shadow" src="../photo/x-ray (1).png" alt="" />
                        <h6>Digital X-Ray</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>)
}
export default Mainservice;