import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Oneser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a1.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Cardiology</h5>
        <p>Cardiology is a medical specialty that deals with the diagnosis, treatment, and prevention of diseases and disorders of the heart and blood vessels (cardiovascular system). Cardiologists are physicians who specialize in this field and are trained to provide comprehensive care for patients with cardiovascular conditions.</p>
        <p>The cardiovascular system is essential for the proper functioning of the body, as it is responsible for pumping oxygenated blood to all organs and tissues. However, various factors such as lifestyle, genetics, and aging can contribute to the development of cardiovascular diseases.</p>
        <h6>Some common cardiovascular conditions that cardiologists diagnose and treat include:</h6>
        <h6>Coronary artery disease:</h6>
        <p>This condition occurs when the blood vessels supplying the heart muscle become narrowed or blocked, leading to reduced blood flow and oxygen to the heart.</p>
        <h6>Heart failure:</h6>
        <p>Heart failure happens when the heart is unable to pump enough blood to meet the body's demands. It can be caused by various conditions like coronary artery disease, high blood pressure, and heart valve disorders.</p>
        <h6>Arrhythmias:</h6>
        <p>Arrhythmias are abnormal heart rhythms that can manifest as a slow, fast, or irregular heartbeat. They can range from harmless to life-threatening and require evaluation and management by a cardiologist.</p>
        <h6>Valvular heart disease:</h6>
        <p>This refers to conditions where the heart valves are diseased or damaged, affecting blood flow within the heart. Valvular heart disease may require surgical intervention, such as valve repair or replacement.</p>
        <h6>Congenital heart defects:</h6>
        <p>These are structural abnormalities present at birth that affect the heart or blood vessels. Cardiologists who specialize in congenital heart disease treat patients of all ages, from newborns to adults.</p>
        <p>Cardiologists use various diagnostic tools and procedures to evaluate patients, such as electrocardiography (ECG/EKG), echocardiography, stress testing, cardiac catheterization, and cardiac imaging. Treatment options can include medication, lifestyle modifications, minimally invasive procedures, or surgery, depending on the specific condition and its severity.</p>
        <p>Prevention is also a crucial aspect of cardiology, and cardiologists often educate patients about risk factors and lifestyle modifications to reduce the likelihood of developing cardiovascular disease. This may involve promoting a healthy diet, regular exercise, smoking cessation, and managing conditions like high blood pressure, diabetes, and high cholesterol.</p>
        <p>It's important to note that while I strive to provide accurate and up-to-date information, it's always advisable to consult with a qualified cardiologist or healthcare professional for specific medical advice or concerns.</p>
    </div>
    </>)
}
export default memo(Oneser)