import { memo, useContext, useState } from "react"
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import { data1 } from "../../App";

function Newservices(){
    const setServiceseta=useContext(data1)
    const [number, setNumber]=useState(6)
    const [noner, setNoner]=useState("block")
    const menu=[
        
        {
            img:"../photo/zz9.jpg",
            name:"Interventional Radiology",
            content:"Radiology is a medical specialty that uses medical imaging techniques...",
            to:"/mainservice/radiology"
        },
        {
            img:"../photo/zz6.jpg",
            name:"Orthopaedics",
            content:"Orthopedics is a medical specialty that focuses on the diagnosis...",
            to:"/mainservice/orthopaedics"
        },
        {
            img:"../photo/zz4.jpg",
            name:"Neurosurgery",
            content:"Neurosurgery focuses on the surgical treatment and management of...",
            to:"/mainservice/neurosurgery"
        },
        {
            img:"../photo/all1.jpg",
            name:"Internal Medicine ",
            content:"Internal Medicine is a medical specialty that focuses on the diagnosis...",
            to:"/mainservice/internalmedicine"
        },
        {
            img:"../photo/zz1.jpg",
            name:"Cardiology",
            content:"Cardiology is the branch of medicine that deals with the diagnosis...",
            to:"/mainservice/cardiology"
        },
        {
            img:"../photo/zz7.jpg",
            name:"Paediatrics",
            content:"Pediatrics is a medical specialty that focuses on the healthcare...",
            to:"/mainservice/paediatrics"
        },
        {
            img:"../photo/mm1.jpg",
            name:"Plastic Surgery",
            content:"Plastic surgery is a surgical specialty that focuses on the...",
            to:"/mainservice/plasticsurgery"
        },
        {
            img:"../photo/ee5.jpg",
            name:"General Surgery",
            content:"General surgery is a medical specialty that focuses on the surgical...",
            to:"/mainservice/generalsurgeon"
        },
        {
            img:"../photo/hhh1.jpg",
            name:"Obstetrics & Gynaecology",
            content:"Obstetrics and Gynaecology (OB/GYN) is a medical specialty...",
            to:"/mainservice/obstetrics"
        },
        {
            img:"../photo/dd1.jpg",
            name:"Anesthesiology & Critical Care",
            content:"Anesthesiology is a medical specialty that focuses on the perioperative...",
            to:"/mainservice/anesthesiology"
        },
        {
            img:"../photo/nn1.jpg",
            name:"Psychiatry",
            content:"Anesthesiology is a medical specialty that focuses on the perioperative...",
            to:"/mainservice/psychiatry"
        },
        {
            img:"../photo/ff1.jpg",
            name:"Physiotherapy",
            content:"Physiotherapy, also known as physical therapy, is a healthcare profession...",
            to:"/mainservice/physiotherapy"
        },
        
        {
            img:"../photo/ggg14.jpg",
            name:"Urology",
            content:"Urology is a medical specialty that focuses on the diagnosis, treatment, and management of...",
            to:"/mainservice/urology"
        },
        // {
        //     img:"../photo/zz3.jpg",
        //     name:"Nephrology",
        //     content:"Nephrology is a medical specialty that focuses on the diagnosis...",
        //     to:"/mainservice/nephrology"
        // },
        // {
        //     img:"../photo/zz10.jpg",
        //     name:"Spine Surgery",
        //     content:"Spine surgery is a surgical specialty that focuses on the diagnosis...",
        //     to:"/mainservice/spinesurgery"
        // },
        // {
        //     img:"../photo/zz5.jpg",
        //     name:"Oncology",
        //     content:"Oncology is a medical specialty that deals with the prevention, diagnosis...",
        //     to:"/mainservice/oncology"
        // },
        // {
        //     img:"../photo/zz2.jpg",
        //     name:"Dermatology",
        //     content:"Dermatology is a branch of medicine that deals with the diagnosis...",
        //     to:"/mainservice/dermatology"
        // },
        // {
        //     img:"../photo/zz8.jpg",
        //     name:"Pulmonology",
        //     content:"Pulmonology is a medical specialty that focuses on the diagnosis...",
        //     to:"/mainservice/pulmonology"
        // },
    ]
    const changer={
        display:`${noner}`
    }
    return (<>
    <section className="mb-5 greybackness  pt-5 pb-5">
    <div className="container">
            <div className="innerbutton">
                <div className="buttnewaa whitetext"><h2>Specialities</h2></div>
            </div>
        </div>
        <div className="container pt-4 pb-3">
            <div className="row">
                {
                    menu.map((elem, num)=>{
                        const {img, name, content,to}=elem
                        {
                                return (
                                    
                <div className="col-xl-4 col-md-6 p-222">
                    
                <div className="row hiders p-3 borderless relative">
                <Link to={to} className="arrower">
                    <img src="../../photo/line.png" alt="" />
                </Link>
                    <div className="col-3 p-0  pt-1 higerli">
                        <LazyLoad className="w-100">
                            <img className="w-100" src={img} alt="" />
                        </LazyLoad>
                    </div>
                    <div className="col-9 pt-1 pl-1 linetext h6ofser">
                        <h6 style={{fontSize:"18px"}}>{name}</h6>
                        <p>{content}</p>
                    </div>
                </div>
                
            </div>
                                )
                        }
                    })
                }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Newservices);