import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Oncology(){
    return (<>
        <section className="mt-3">
            <div className="container">
                <div className="row">
                    <h2>Oncology</h2>
                    <p>Oncology is a medical specialty that focuses on the prevention, diagnosis, and treatment of cancer. Oncologists are medical doctors who specialize in the field of oncology and are responsible for managing all aspects of cancer care, including determining the most appropriate treatment options, monitoring patients' progress, and providing supportive care.</p>
                </div>
            </div>
        </section>
    <section className="mt-3 py-5 backgrey">
        <div className="container">
            <div className="centers">
                <h5>Oncology encompasses several subspecialties, including.</h5>
            </div>
            <div className="row">
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ii1.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Medical Oncology</h6>
                            <p>Medical oncologists are primarily involved in the non-surgical treatment of cancer.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ii2.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Surgical Oncology</h6>
                            <p>Surgical oncologists specialize in the surgical management of cancer. They perform biopsies to diagnose cancer, as well as remove tumors and surrounding tissue through surgical procedures.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ii3.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Radiation Oncology</h6>
                            <p>Radiation oncologists specialize in using radiation therapy to treat cancer. They determine the appropriate radiation treatment plans, and monitor patients throughout the course of treatment. </p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ii4.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Pediatric Oncology</h6>
                            <p>Pediatric oncologists specialize in diagnosing and treating cancer in children. They have expertise in managing the unique types of cancers that occur in children and providing age-appropriate care.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 p-222">
                    <div className="row hiders p-1">
                        <div className="col-4 higerli">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/ii5.jpg" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 p-1 linetext">
                            <h6>Hematologic Oncology</h6>
                            <p>Hematologic oncologists focus on cancers that affect the blood and blood-forming tissues, such as leukemia, lymphoma, and multiple myeloma.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-xl-4 col-md-6 col-12 p-3">
                    <div className="row hiderss p-2">
                        <div className="col-4 hjddwk">
                            <LazyLoad className="w-100">
                                <img className="w-100" src="../../photo/doctor.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="col-8 tentes">
                            <div>
                                <h5>Dr Disha Shetty</h5>
                                <h6>Cardiology</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>)
}
export default memo(Oncology)