import { useState } from "react";
import Footer from "../Footer";
import HeaderTop from "../Header-top";
import Headmenu from "../Headmenu";
import Mobilemenu from "../Mobile-menu";
import Serviceinfo from "../Serviceinfo";
import Servicesbanner from "./Servicebanner";
import Mainserviceser from "./Mainserviceser";
function Services(props){
    const [mobcontrol, setMobcontrol]=useState("none")
    return (<>
    <div className="outerdiv">
        <HeaderTop/>
        <Headmenu mobcontrol={mobcontrol} setMobcontrol={setMobcontrol} serselect={props.serselect} setSerselect={props.setSerselect}/>
        <Servicesbanner/>
        <Mainserviceser/>
        <Serviceinfo serselect={props.serselect} setSerselect={props.setSerselect}/>
        <Footer serselect={props.serselect} setSerselect={props.setSerselect}/>
    </div>
        <Mobilemenu mobcontrol={mobcontrol} setMobcontrol={setMobcontrol} serselect={props.serselect} setSerselect={props.setSerselect}/>
    </>)
}
export default Services;