import { useState } from "react";
import Footer from "../Footer";
import HeaderTop from "../Header-top";
import Headmenu from "../Headmenu";
import Mobilemenu from "../Mobile-menu";
import Aboutbanner from "./Aboutbanner";
import Abouthospital from "./AboutHospital";
import Aboutdoctor from "./Aboutdoctor";
import Vissionandmission from "./VissionandMission";
import Headertwo from "../NewSections/Headertwo";
import Newfooter from "../NewSections/Newfooter";
import Reviewbox from "../NewSections/Reviewbox";
import Newcontactin from "../NewSections/Newcontactin";
import Headernewtop from "../NewSections/Headernewtop";
import ContactUsinfo from "../CintactUsinfo";
import Mobcontroller from "../ContactButton/Mobcontroller";
import Contactbutton from "../ContactButton/Contactbutton";
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo";
import Newservicebanner from "../Newservicesbox/Newservicebanner";
import Vm from "./vm";
import Contactinforlink from "../Newservicesbox/Contactinforlink";
import { useEffect } from "react";
import Functionmain from "../Functionmain";
import bannercontent from "../Newservicesbox/Bannercontent";
import Contactinbox from "../NewSections/Contactinbox";
import Numberinfo from "../Newservicesbox/Numberinfo";

function About(props){
    const [mobcontrol, setMobcontrol]=useState("none")
    useEffect(()=>{
        Functionmain()
    },[])
    return (<>
    <div className="outerdiv">
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
    <Headernewtop/>
    
    {
        bannercontent.map((elem)=>{
            const {page, one, two}=elem
            if("about"==page)
            {
                return (<>
                    <Newservicebanner one={one} two={two}/>
                    </>)
            }
        })
    }
        <Abouthospital/>
        {/* <Newcontactin/> */}
        {/* <Aboutdoctor/> */}
        {/* <Vm/> */}
        <Numberinfo/>
    <Contactinbox/>
        <Vissionandmission/>
        
        {/* <Reviewbox/> */}
        {/* <ContactUsinfo/> */}
        <div className="mt-5">
        <Contactinforlink/>
        </div>
        <Newfooter/>
    </div>
        <Mobilemenu mobcontrol={mobcontrol} setMobcontrol={setMobcontrol} serselect={props.serselect} setSerselect={props.setSerselect}/>
    </>)
}
export default About;