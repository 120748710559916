import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Oneser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a5.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>General Surgeon</h5>
        <p>General surgery is a medical specialty that focuses on the surgical management of a wide range of diseases, injuries, and conditions. General surgeons are medical doctors who specialize in performing surgical procedures and providing pre- and post-operative care for patients.</p>
        <p>General surgeons have a broad knowledge base and skill set that allows them to perform surgeries on various parts of the body. Some of the common areas of focus within general surgery include:</p>
        <h6>Abdominal surgery:</h6>
        <p>General surgeons perform procedures involving the organs within the abdominal cavity, such as the appendix, gallbladder, intestines, liver, and stomach. Examples of abdominal surgeries include appendectomy, cholecystectomy, hernia repair, and colorectal surgeries.</p>
        <h6>Breast surgery:</h6>
        <p>General surgeons are involved in the diagnosis and surgical treatment of breast conditions, including breast cancer. They may perform procedures such as lumpectomy (removal of a breast lump), mastectomy (removal of the breast), or lymph node dissection.</p>
        <h6>Thyroid and parathyroid surgery:</h6>
        <p>General surgeons perform procedures to treat conditions affecting the thyroid and parathyroid glands, including thyroid nodules, thyroid cancer, and hyperparathyroidism. These surgeries may involve removing part or all of the thyroid or parathyroid glands.</p>
        <h6>Skin and soft tissue surgery: </h6>
        <p>General surgeons perform procedures to remove skin lesions, cysts, tumors, and perform skin grafts or reconstructive procedures. They may also manage conditions such as abscesses, cellulitis, and infected wounds.</p>
        <h6>Vascular surgery:</h6>
        <p>General surgeons may perform vascular procedures to treat conditions such as varicose veins, arterial blockages, aneurysms, or perform procedures to improve blood flow, such as arteriovenous fistula creation for dialysis patients.</p>
        <p>General surgeons work closely with other healthcare professionals, including anesthesiologists, nurses, and surgical teams, to provide comprehensive care to patients. They evaluate patients, recommend surgical interventions, perform surgeries, manage post-operative care, and monitor patients' progress. They may also provide counseling and education to patients regarding surgical procedures, potential risks, and post-operative recovery.</p>
        <p>In addition to surgical skills, general surgeons may also have expertise in endoscopy (using a flexible tube with a camera to visualize internal organs) and minimally invasive techniques, allowing for smaller incisions, reduced scarring, and faster recovery times in certain cases.</p>
        <p>It's important to note that while general surgeons are skilled in performing a wide range of surgical procedures, there are cases where specialized surgeons may be required. In such situations, general surgeons may refer patients to appropriate specialists for more complex or specialized surgical interventions.</p>
        <p>If you have specific concerns or conditions requiring surgical management, it is recommended to consult with a qualified general surgeon or healthcare professional for a thorough evaluation and personalized care plan.</p>
    </div>
    </>)
}
export default memo(Oneser)