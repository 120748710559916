import { memo } from "react"
import { Link } from "react-router-dom"

function Media(){
    return (<>
    <section className="secmedia">
        <div className="container">
            <div className="row">
                <div className="col-12 latesannu mb-2">
                    <h6>MEDIA CENTER</h6>
                    <h1>Latest Announcement</h1>
                </div>
                <div className="col-12">
                    <div className="mb-4">
                        <p className="mb-1">Dec 2th,2024</p>
                        <Link className="marlikm">Introducing Karnataka’s First Cryo Laser and Cryo Sclerotherapy (CLaCS) at Adhventha Hospital, Bangalore</Link>
                    </div>
                    <div className="mb-4">
                        <p className="mb-1">Aug 23th,2024</p>
                        <Link className="marlikm">Laser Treatment for Varicose Veins and Thyroid Ablation</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Media)