import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Gallerybox(){
    const menu=[
        {
            img:"../photo/aa.jpg",
        },
        {
            img:"../photo/ab.jpg",
        },
        {
            img:"../photo/ac.jpg",
        },
        {
            img:"../photo/ad.jpg",
        },
        {
            img:"../photo/ae.jpg",
        },
        {
            img:"../photo/af.jpg",
        },
        {
            img:"../photo/ag.jpg",
        },
        {
            img:"../photo/ah.jpg",
        },
    ]
    return (<>
    <section>
        <div className="container mt-4">
            
        <div className="row">
            {
                menu.map((elem)=>{
                    const {img}=elem
                    {
                        return (<>
                        <LazyLoad className="col-xl-4 col-lg-4 col-md-6 col-12 mb-3 mt-3">
                <img style={{width:"100%"}} src={img} alt="" />
            </LazyLoad>
                        </>)
                    }
                })
            }
        </div>
        </div>
    </section>
    </>)
}
export default memo(Gallerybox)