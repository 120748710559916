import "./App.css";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home/Home";
import About from "./component/About/About";
import Services from "./component/Services/services";
import ContactUs from "./component/ContactUs/ContactUs";
import Hospitalservices from "./component/Servicebody/Hospitalservice";
import { createContext, useState } from "react";
import Servicemain from "./component/Newserfolder/Servicemain";
import Ourdoctor from "./component/Ourdoctor/Ourdoctor";
import Gallery from "./component/Gallery/Gallery";
import Blog from "./component/Blog/Blog";
import SubBlog from "./component/Blog/SubBlog";
import Cardiology from "./component/Newservicesbox/Services/Cardiology";
import Dermatology from "./component/Newservicesbox/Services/Dermatology";
import GeneralMedicine from "./component/Newservicesbox/Services/GeneralMedicine";
import Anesthesiology from "./component/Newservicesbox/Services/Anesthesiology";
import Generalsurgeon from "./component/Newservicesbox/Services/Generalsurgeon";
import Physiotherapy from "./component/Newservicesbox/Services/Physiotherapy";
import Nephrology from "./component/Newservicesbox/Services/Nephrology";
import Neurosurgery from "./component/Newservicesbox/Services/Neurosurgery";
import Oncology from "./component/Newservicesbox/Services/Oncology";
import Orthopaedics from "./component/Newservicesbox/Services/Orthopaedics";
import Paediatrics from "./component/Newservicesbox/Services/Paediatrics";
import Pathology from "./component/Newservicesbox/Services/Pathology";
import Plasticsurgery from "./component/Newservicesbox/Services/Plasticsurgery";
import Psychiatry from "./component/Newservicesbox/Services/Psychiatry";
import Pulmonology from "./component/Newservicesbox/Services/Pulmonology";
import Radiology from "./component/Newservicesbox/Services/Radiology";
import Spinesurgery from "./component/Newservicesbox/Services/Spinesurgery";
import Urology from "./component/Newservicesbox/Services/Urology";
import Vascularsurgery from "./component/Newservicesbox/Services/Vascularsurgery";
import Internalmedicine from "./component/Newservicesbox/Services/Internalmedicine";
import Obstetrics from "./component/Newservicesbox/Services/Obstetrics";
import Emty from "./component/Emty";

const data = createContext();
const data1 = createContext();
const data2 = createContext();
const data3 = createContext();
const data4 = createContext();
const data5 = createContext();

function App() {
  const [serselect, setSerselect] = useState("Cardiology");
  const [blogbox, setBlogbox] = useState(
    "What Conditions does a urologist treat?"
  );
  const [selectfoem, setForm] = useState("none");
  return (
    <data.Provider value={serselect}>
      <data1.Provider value={setSerselect}>
        <data2.Provider value={blogbox}>
          <data3.Provider value={setBlogbox}>
            <data4.Provider value={selectfoem}>
              <data5.Provider value={setForm}>
                <BrowserRouter>
                  <Routes basename="demo36.appaddindia.net">
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/services" element={<Services />}></Route>
                    <Route path="/ourdoctor" element={<Ourdoctor />}></Route>
                    <Route path="/gallery" element={<Gallery />}></Route>
                    <Route path="/blog" element={<Blog />}></Route>
                    <Route path="/contact" element={<ContactUs />}></Route>
                    <Route path="/mainservice" element={<Servicemain />}>
                      <Route
                        path="/mainservice/cardiology"
                        element={<Cardiology />}
                      >
                        <Route
                          path="/mainservice/cardiology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/dermatology"
                        element={<Dermatology />}
                      >
                        <Route
                          path="/mainservice/dermatology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/generalmedicine"
                        element={<GeneralMedicine />}
                      >
                        <Route
                          path="/mainservice/generalmedicine/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/anesthesiology"
                        element={<Anesthesiology />}
                      >
                        <Route
                          path="/mainservice/anesthesiology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/generalsurgeon"
                        element={<Generalsurgeon />}
                      >
                        <Route
                          path="/mainservice/generalsurgeon/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/physiotherapy"
                        element={<Physiotherapy />}
                      >
                        <Route
                          path="/mainservice/physiotherapy/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/nephrology"
                        element={<Nephrology />}
                      >
                        <Route
                          path="/mainservice/nephrology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/neurosurgery"
                        element={<Neurosurgery />}
                      >
                        <Route
                          path="/mainservice/neurosurgery/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/oncology"
                        element={<Oncology />}
                      >
                        <Route
                          path="/mainservice/oncology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/orthopaedics"
                        element={<Orthopaedics />}
                      >
                        <Route
                          path="/mainservice/orthopaedics/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/paediatrics"
                        element={<Paediatrics />}
                      >
                        <Route
                          path="/mainservice/paediatrics/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/pathology"
                        element={<Pathology />}
                      >
                        <Route
                          path="/mainservice/pathology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/plasticsurgery"
                        element={<Plasticsurgery />}
                      >
                        <Route
                          path="/mainservice/plasticsurgery/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/psychiatry"
                        element={<Psychiatry />}
                      >
                        <Route
                          path="/mainservice/psychiatry/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/pulmonology"
                        element={<Pulmonology />}
                      >
                        <Route
                          path="/mainservice/pulmonology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/radiology"
                        element={<Radiology />}
                      >
                        <Route
                          path="/mainservice/radiology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/spinesurgery"
                        element={<Spinesurgery />}
                      >
                        <Route
                          path="/mainservice/spinesurgery/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/obstetrics"
                        element={<Obstetrics />}
                      >
                        <Route
                          path="/mainservice/obstetrics/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route path="/mainservice/urology" element={<Urology />}>
                        <Route
                          path="/mainservice/urology/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/vascularsurgery"
                        element={<Vascularsurgery />}
                      >
                        <Route
                          path="/mainservice/vascularsurgery/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                      <Route
                        path="/mainservice/internalmedicine"
                        element={<Internalmedicine />}
                      >
                        <Route
                          path="/mainservice/internalmedicine/subdepartment"
                          element={<Emty />}
                        ></Route>
                      </Route>
                    </Route>
                    <Route path="/subblog" element={<SubBlog />}></Route>
                  </Routes>
                </BrowserRouter>
              </data5.Provider>
            </data4.Provider>
          </data3.Provider>
        </data2.Provider>
      </data1.Provider>
    </data.Provider>
  );
}

export default App;
export { data, data1, data2, data3, data4, data5 };
