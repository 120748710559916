import { memo, useContext, useEffect, useState } from "react"
import Bloglinks from "./Bloglinks"
import Headernewtop from "../NewSections/Headernewtop"
import Subbanner from "./Subbanner"
import Newfooter from "../NewSections/Newfooter"
import OneSub from "./SubBlog/OneSub"
import Subin from "./SubInfo"
import { data2 } from "../../App"
import Mobcontroller from "../ContactButton/Mobcontroller"
import Contactbutton from "../ContactButton/Contactbutton"
import Sidelogo from "../ContactButton/Sidelogo/Sidelogo"
import Blogbanners from "../Newservicesbox/Blog/Blogbanners"
import Media from "./Media"
import Mediacenter from "./Mediacenter"
import Functionmain from "../Functionmain"

function Subblog(){
    const changer=useContext(data2)
    const [menu, setMenu]=useState(Subin)
    useEffect(()=>{
        const update=Subin.filter((elemr)=>{
            return elemr.name === changer;
        })
        setMenu(update)
    })
    useEffect(()=>{
        Functionmain()
    },[])
    return (<>
    <Headernewtop/>
    <Mobcontroller/>
    <Contactbutton/>
    <Sidelogo/>
    <Blogbanners/>
{
    menu.map((elem)=>{
        const {name, address}=elem
        return (<>
    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mt-4">
                    {address}
                </div>
                <div className="col-lg-4 mt-4">
                    <Mediacenter/>
                <Media/>
                </div>
            </div>
        </div>
    </section>
        </>)
    })
}
    <Newfooter/>
    </>)
}
export default memo(Subblog)