import { memo, useEffect, useState } from "react"
import NewLinkbox from "./NewLinkbox"

function Newfixheader(props){
    const [noner, setNoner]=useState("none")
    useEffect(()=>{
        window.addEventListener('scroll',()=>{
            if(window.scrollY>100){
                setNoner("block")
            }else{
                setNoner("none")
            }
        })
    })
    const changer={
        display:`${noner}`,
    }
    return (<>
    <header style={changer} className="newfixheader">
        <NewLinkbox first={props.first} setFirst={props.setFirst} second={props.second} setSecond={props.setSecond} setThree={props.setThree}/>
    </header>
    </>)
}
export default memo(Newfixheader)