import { memo } from "react"
import { Link } from "react-router-dom";

function Newfirstmenu(props){
    const changer={
        display:`${props.first}`
    }
    return (<>
    <div style={changer} className="firstmenu">
        <div className="container ">
            <div className="row">
                <div className="newfootlo mb-3 mt-3">
                        <img src="../photo/Framelogo.png" alt="" />
                </div>
                <div className="col-12">
                    <div className="newdirectli mb-3 innersfirst">
                        <h4>Adhventha Hospital</h4>
                    </div>
                    <Link to="/" className="newlinkbox noner">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>Home</h6>
                    </Link>
                    <Link to="/about" className="newlinkbox">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>About Us</h6>
                    </Link>
                    <Link to="/ourdoctor" className="newlinkbox">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>Our Doctors</h6>
                    </Link>
                    <Link onClick={()=>{
                        props.setSecond("block")
                    }} className="newlinkbox">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>Departments</h6>
                    </Link>
                    <Link to="/blog" className="newlinkbox">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>Blog</h6>
                    </Link>
                    <Link to="/gallery" className="newlinkbox">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>Gallery</h6>
                    </Link>
                    <Link to="/contact" className="newlinkbox">
                        <span className="newliim footle mro">
                            <img src="../photo/right.png" alt="" />
                        </span>
                        <h6>Contact Us</h6>
                    </Link>
                </div> 
            </div>
        </div>
                    <div onClick={()=>{
                        props.setFirst("none")
                        props.setThree("block")
                    }} className="newlinkboxb">
                        <h6>Back</h6>
                    </div>
    </div>
    </>)
}
export default memo(Newfirstmenu);