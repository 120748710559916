import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function FiveSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b4.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Modern life being fast and full of stress leaves its toll upon human beings. In most of the metropolitan cities people are suffering from diseases like diabetes, high blood pressure, thyroid disorders and cancer. Pollution and stress are considered to be the primary reasons of these diseases. Nevertheless, there are ways to keep oneself away from these diseases. Physical exercise plays a vital role behind healthy living. It has numerous benefits and most importantly it improves the quality of life. The benefits of physical exercise can be enjoyed by anyone regardless of age, sex and physical ability.</p>
        <h5>Exercise Controls Weight</h5>
        <p>To stay fit and healthy, it is mandatory to control weight. Obesity which is the root cause of most of the diseases can be controlled through regular exercise. It burns out calories. With intense exercise it is possible to burn calories easily. But to burn calories, you need not require hitting the gym. Exercises can be done at home easily following certain guidelines. To reap the benefits, exercises should be done regularly at a certain time at least for 30 minutes. But elderly people must consult the doctor before starting any particular exercises.</p>
        <p>By controlling weight, it also makes you more confident and smart. With a better appearance, it heightens your confidence and improve your self-esteem.</p>
        <h5>Exercise Keeps Away Diseases</h5>
        <p>Regular exercise lowers the chances of cardiovascular diseases. Doing exercise regularly benefits a person by boosting high-density lipoprotein (HDL) cholesterol known as the “good” cholesterol. It also decreases unhealthy triglycerides in blood. It helps in smooth flowing of blood through the heart into different parts of the body. Thus, regular exercise minimises the chances of various diseases such as: Stroke, Metabolic syndrome, High blood pressure, Type 2 diabetes, Depression, Anxiety, Many types of cancer, Arthritis, Falls</p>
        <h5>Exercise Boost Energy</h5>
        <p>Maintaining regular schedule often you feel tired and bored. It seems drudgery to you. But with regular exercise you can boost up your energy. Regular exercise improves blood circulation in your body and keeps your heart and lung in better condition. It supplies oxygen and nutrients to the tissue, strengthens muscles and boosts up endurance. If the tissues receive more oxygen, you naturally get more energy and vitality to work.</p>
        <h5>Some Guidelines for Physical Exercise</h5>
        <p>To enjoy the benefits of physical exercise, you must exercise regularly for at least 30 minutes. These may include yoga, free hand exercise, walking, swimming and cycling etc. Even initially walking up the staircase instead of using elevator, walking to the market leaving your car behind will benefit your health.</p>
        <p>Health professionals and researchers recommend increasing physical exercise gradually. Weekly 300 minutes of exercise is advised by them to enjoy the best results. For shedding extra weight weekly 300 minutes of exercise is a must. Exercise for muscle strengthening at least two days every week is advised by them.</p>
        <p>It is always advisable to consult your doctor before starting any exercise if you have some chronic health issues such as: heart disease, diabetes, and arthritis.</p>
    </div>
    </>)
}
export default memo(FiveSub)