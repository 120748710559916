import { memo } from "react"
import LazyLoad from "react-lazy-load"
import Twoser from "../../Newserfolder/Subservices/Twoser"

function FourSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b1.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Diabetes commonly known as life style disorder often leaves its toll upon the mental health of people. People detected with diabetes have to monitor their blood sugar regularly, remember to take medicines on time and need to remain active to keep blood sugar in control. Nevertheless, the rise and fall of blood sugar and complications related to diabetes often worry them a lot. While medical advices available to address diabetes- the metabolic disorder, there are hardly any guidelines available to address the fear related to diabetes. The phobia originating due to diabetes is known as diabetes distress. It often appears challenging and requires serious psychological intervention to deal with.</p>
        <h5>Needle Phobia</h5>
        <p>Many people develop a fear of needle. They are scared of taking insulin and fret over a lot thinking of the pain of taking insulin. Doctors find such patients take longer time to control their sugar level and require constant counselling to overcome their fear.</p>
        <p>
Such people need to learn relaxation technique to get over their fear. A few deep breathing relaxes the mind before taking insulin. The relaxed mind does not feel the pain of injection. In some cases psychologists work closely with these people to help them overcome their phobia.</p>
<h5>Fear in Anticipation of Developing Complications</h5>
<p>Diabetes often causes heart diseases, nerve complications, eye and kidney problems. Some people anticipate these problems before hand. Thinking of these problems they feel distressed. But with medication, proper diet and regular exercise, it is possible to control diabetes. The advanced medical treatment aims at protecting diabetes patients from being a victim of other ailments.</p>
<p>The doctors’ advice to follow a routine life along with exercise help patients get over their fear. Medical counselling does wonder to ward off the phobia of developing further complications due to diabetes.</p>
<h5>Fear of developing Hypoglycaemia</h5>
<p>Hypoglycaemia is a medical condition when a person’s sugar level lowers down. Due to hypoglycaemia people experience rapid heartbeat, blurry vision, shaking, loss of consciousness, seizure and coma, hunger and headache etc. The fear of hypoglycaemia often attacks at night and so to control this fear people restrict certain activities which no doubt acts adversely upon them.

Whenever diabetes patients show symptoms of glycaemia, they should be given 15gms of carbohydrates to consume. Hypoglycaemia condition must be managed with immediate consultation with the doctor.</p>
<h5>RUnwanted Anxiety</h5>
<p>Often diabetes patients suffer from anxiety over their health issues. They feel disturbed thinking over their health condition, other people’s opinions about them, their diet and life style.

</p>
<p>In such cases, the patients must consult with the doctor and feel free to tell them about their anxiety. Besides prescribing certain medicines to overcome anxiety, the doctors may recommend for some meditation and relaxation techniques which ease out their anxiety. To win over anxiety, the patients must share their feelings with their near and dear ones. They should focus their attention on things they enjoy the most. Talking with others and sharing feelings help patients learn how other people are managing their diabetes. This would surely be a lesson for them to control their diabetes and overcome anxiety.</p>
    </div>
    </>)
}
export default memo(FourSub)