import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Numberinfo(){
    const menu=[
        {
            name:"20k+",
            num:"Happy Patients",
            img:"../../photo/Oncology.png"
        },
        {
            name:"13+",
            num:"OPD Departments",
            img:"../../photo/Oncology.png"
        },
        {
            name:"23+",
            num:"Experienced Doctors",
            img:"../../photo/Oncology.png"
        },
    ]
    return (<>
    <section className="otercolor pt-5 pb-5 mt-5">
        <div className="container center-text">
            <h2>Adhventha Hospital Key Matrices</h2>
        </div>
        <div className="container py-3">
            <div className="row">
            {
                menu.map((elem)=>{
                    const {name, num, img}=elem
                    {
                        return (<>
                        <div className="col-lg-4 col-md-6 col-12 borderra mt-3">
                <div className="numinfo">
                    <div className="numberinfot">
                        <h2>{name}</h2>
                        <h5>{num}</h5>
                    </div>
                    <div className="imgeininfo">
                        <LazyLoad className="w-100">
                            <img className="w-100" src={img} alt="" />
                        </LazyLoad>
                    </div>
                </div>
            </div>
                        </>)
                    }
                })
            }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Numberinfo)