import { memo } from "react"
import LazyLoad from "react-lazy-load"

function LevenSub(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/b10.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3 h5control">
        <p>Posted June 13th,2022 by Adhventha Hospital</p>
        <p>Teenage is considered to be one of the most important cross roads of life. At this stage, the children and young adults go through certain changes including physical, emotional, cognitive, social and psychological changes. Family friends, teachers, schools and environment play an important role in the life of all the teenagers. Some children deal with the changes in adolescence quite efficiently while some others being unable to cope with the changes land themselves at a tumultuous situation. In most cases it is found that the adolescent children cannot speak of their problems. They behave peculiarly and often want to hide their problems. In extreme cases, they choose some destructive steps. Hence, if the teenage health problems are not addressed at the beginning, it may take up larger shape and even spoil their entire life.</p>
        <p>The teenage health problems are generally complex and require a comprehensive and bio- psychological approach to treat them. Research says that the problems faced by the adolescent are more of psychological, social or environmental rather than biological or physical. They suffer from stress and trauma, anxiety, tension and related health hazards. Under the stress of their inner problem, they behave peculiarly or adopt various self destructive measures that affect their overall growth and development. World Health Organization (WHO) has categorised the adolescent problems such as:Depression and SuicideViolent behaviourConsumption of alcohol and other drugsUse of TobaccoHIV and other infectious diseaseSexual health/ infectious diseaseEarly pregnancy and Child birthMalnutrition and obesityExercise and nutritionAdolescent oncology</p>
        <p>Teenage health problems being the most delicate issues must be dealt with much care, precaution and confidentiality. Adolescent health care includes mental health services, child protection, drug and alcohol services and sexual health services. The doctors from multi- disciplinary areas work together to address the teenage health problems. Often the teenagers require psychological and career counselling provided by the specialised doctors and nurses.</p>
    </div>
    </>)
}
export default memo(LevenSub)