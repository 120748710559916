import { memo, useState } from "react"
import { Link } from "react-router-dom"

function Newdoctorlist({setNames}){
    const [dis, setDis]=useState("none")
    const menu=[
        {
            nmae:"All",
            img:"../photo/patient.png",
            names:"Dr Bhaskar M V",
        },
        {
            nmae:"Interventional Radiologist",
            img:"../photo/patient.png",
            names:"Dr Bhaskar M V",
        },
        {
            nmae:"Orthopedics",
            img:"../photo/patient.png",
            names:"Dr Ambuj",
        },
        // {
        //     nmae:"Endovascular Surgeon",
        //     img:"../photo/patient.png",
        //     names:"Dr Bhaskar M V",
        // },
        // {
        //     nmae:"Diabetologist",
        //     img:"../photo/patient.png",
        //     names:"Dr Maheshwari",
        // },
        {
            nmae:"Neurosurgery",
            img:"../photo/patient.png",
            names:"Dr Indira Devi",
        },
        {
            nmae:"Internal Medicine",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        {
            nmae:"Cardiology",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        // {
        //     nmae:"Consultant",
        //     img:"../photo/patient.png",
        //     names:"Dr Archana",
        // },
        // {
        //     nmae:"Laproscopic Surgeon",
        //     img:"../photo/patient.png",
        //     names:"Dr Muni Reddy",
        // },
        {
            nmae:"Pediatric",
            img:"../photo/patient.png",
            names:"Dr Varsha Manohar",
        },
        {
            nmae:"Plastic Surgery",
            img:"../photo/patient.png",
            names:"Dr Suresh",
        },
        {
            nmae:"General Surgery",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        {
            nmae:"Obstetrics & Gynaecology",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        {
            nmae:"Anesthesiology & Critical Care",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        // {
        //     nmae:"General Physician",
        //     img:"../photo/patient.png",
        //     names:"Dr A H Wahid",
        // },
        {
            nmae:"Psychiatry",
            img:"../photo/patient.png",
            names:"Dr Anupama",
        },
        {
            nmae:"Psychotherapy",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        
        {
            nmae:"Urology",
            img:"../photo/patient.png",
            names:"Dr Shaik Ahmed",
        },
        // {
        //     nmae:"Gastroenterology",
        //     img:"../photo/patient.png",
        //     names:"Dr Shaik Ahmed",
        // },
    ]
    const changer={
        display:`${dis}`
    }
    
    return (<>
    <div className="mb-3 row judyj">
                    <select onChange={(val)=>{
                        setNames(val.target.value)
                    }} className="col-xl-4 col-lg-4 col-md-6 col-12 bodiess">
                    {
                        menu.map((elem)=>{
                            const {nmae, img}=elem
                            {
                                return (<>
                                <option style={{border:"0px",backgroundColor:"white"}} className="">
                                <div style={{border:"0px",border:"1.5px solid white"}} className="">
                                    <span className="">
                                        
                                    <img src={img} alt="" />
                                    </span>
                                    <h6>{nmae}</h6>
                                </div>

                                </option>
                                    </>)
                            }
                        })
                    }
                    </select>
                    <div className="col-md-4 linesjbcdhzmn ">
            <h2>Our Doctors</h2>
        </div>     
        
        <div className="col-md-4 linesjbcdhzmn gogoer">
            <h2></h2>
        </div>   
    </div>
    </>)
}
export default memo(Newdoctorlist)