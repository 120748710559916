import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Eightenser(){
    return (<>
    <div className="mb-3">
        <LazyLoad>
            <img style={{width:"100%"}} src="../photo/a18.jpg" alt="" />
        </LazyLoad>
    </div>
    <div className="mb-3">
        <h5>Urology</h5>
        <p>Urology is a medical specialty that focuses on the diagnosis, treatment, and management of conditions related to the urinary tract system and the male reproductive system. Urologists are medical doctors who specialize in urology and are trained to address a wide range of urological disorders in both males and females.</p>
        <p>The urinary tract system includes the kidneys, bladder, ureters (tubes that connect the kidneys to the bladder), and urethra (tube that carries urine from the bladder out of the body). The male reproductive system includes the testes, prostate gland, penis, and associated structures.</p>
        <h6>Urologists are skilled in both surgical and non-surgical treatments for various urological conditions, which can include:</h6>
        <h6>Urinary Tract Infections (UTIs):</h6>
        <p>Urologists diagnose and treat UTIs, which are infections that affect any part of the urinary system. They may prescribe antibiotics and provide recommendations for preventing recurrent infections.</p>
        <h6>Kidney Stones:</h6>
        <p>Urologists manage kidney stones, which are hard mineral and salt deposits that form in the kidneys. They may employ various treatment methods, such as medication, dietary changes, and in some cases, surgical procedures to remove or break up large stones.</p>
        <h6>Urinary Incontinence:</h6>
        <p>Urologists evaluate and treat urinary incontinence, which is the involuntary loss of urine. Treatment options may include lifestyle modifications, pelvic floor exercises, medications, or surgical interventions to improve bladder control.</p>
        <h6>Benign Prostatic Hyperplasia (BPH): </h6>
        <p>Urologists diagnose and manage BPH, a non-cancerous enlargement of the prostate gland that can cause urinary symptoms in men. Treatment options range from medications to surgical procedures, such as transurethral resection of the prostate (TURP), to relieve symptoms and improve urinary flow.</p>
        <h6>Prostate Cancer:</h6>
        <p>Urologists are involved in the diagnosis and treatment of prostate cancer. They perform prostate biopsies, recommend appropriate treatment options (including surgery, radiation therapy, hormone therapy, or active surveillance), and monitor disease progression.</p>
        <h6>Erectile Dysfunction: </h6>
        <p>Urologists evaluate and manage erectile dysfunction, which is the inability to achieve or maintain an erection sufficient for sexual intercourse. They may explore various treatment approaches, including medications, hormone therapy, penile implants, or psychological counseling.</p>
        <p>Urologists often work closely with other healthcare professionals, such as nephrologists (kidney specialists), oncologists, and reproductive endocrinologists, to provide comprehensive care to their patients. They may collaborate on complex cases or refer patients to specialists when necessary.</p>
        <p>If you are experiencing urological symptoms or have concerns about your urinary or reproductive health, it is advisable to consult with a urologist. They can evaluate your symptoms, perform necessary tests and procedures, provide an accurate diagnosis, and recommend appropriate treatment options to address your specific condition.</p>
    </div>
    </>)
}
export default memo(Eightenser)